import { createSlice } from "@reduxjs/toolkit";


interface initialState {
    selection: string,
    color: string,
    style: string,
    size: number,
    filteredDice: string[]
}

const initialState: initialState = {
    selection: '',
    color: '',
    style: '',
    size: 1,
    filteredDice: []
}


const diceFiltersSlice = createSlice({
    name: 'diceFilters',
    initialState,
    reducers: {
        setSelection: (state, action) => { state.selection = action.payload },
        setColor: (state, action) => { state.color = action.payload },
        setStyle: (state, action) => { state.style = action.payload },
        setSize: (state, action) => { state.size = action.payload },
        setFilteredDice: (state, action) => { state.filteredDice = action.payload }
    },
    selectors: {
        getSelection: (state) => state.selection,
        getColor: (state) => state.color,
        getStyle: (state) => state.style,
        getSize: (state) => state.size,
        getFilteredDice: (state) => state.filteredDice
    }
})

export const {
    setSelection, setColor,
    setStyle, setSize,
    setFilteredDice
} = diceFiltersSlice.actions;

export const {
    getSelection, getColor,
    getStyle, getSize,
    getFilteredDice
} = diceFiltersSlice.selectors;

export default diceFiltersSlice;