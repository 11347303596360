import { configureStore } from '@reduxjs/toolkit';
import diceFilters from './diceFilters';
import cartSlice from './cart';
import productsSlice from './products';
import previewerSlice from './preview';
import metaSlice from './meta';

const store = configureStore({ 
    reducer: {
        cart: cartSlice.reducer,
        diceFilters: diceFilters.reducer,
        products: productsSlice.reducer,
        previewer: previewerSlice.reducer,
        meta: metaSlice.reducer
    }
});

export default store;