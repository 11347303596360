import React from 'react';
import { useSelector } from 'react-redux';
import { getItems, removeItem } from '../../../../store/cart';
import { THGProduct } from '../../../../../types/types';
import { useDispatch } from 'react-redux';
// import { getProducts } from '../../../../store/products';
// import { AnimatePresence } from 'framer-motion';

const CartList: React.FC = () => {
    const items = useSelector(getItems);
    // const products = useSelector(getProducts);
    const itemsList: THGProduct[] = [];
    const dispatch = useDispatch();

    for (let i in items) {
        itemsList.push(items[i]);
    }

    return (
        <div className='flex justify-center w-full px-6 sm:px-8'>
            <table className='table-auto w-full sm:w-auto sm:min-w-72'>
                <thead>
                    <tr className='gap-x-2 text-teko font-bold text-2xl text-left'>
                        <th>Item</th>
                        <th>Qty.</th>
                        <th>Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {itemsList.map((v, i) => (
                        <tr key={i} className='border-b-2 border-b-red-100'>
                            <td>{v.serial}</td>
                            {/* Future image */}
                            <td>{v.quantity}</td>
                            <td>{`$${(v.quantity * v.price).toFixed(2)}`}</td>
                            <td>
                                <button
                                    type='button'
                                    className='hover:cursor-pointer rounded-sm px-2 py-1 text-lg text-white bg-red-700'
                                    onClick={() => dispatch(removeItem(v))}
                                >
                                    X
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CartList;