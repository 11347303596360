/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 -t misc\product_boogie_4.glb src\3d\Boogie3D2.tsx 
*/
//@ts-nocheck

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { motion } from 'framer-motion-3d'

type GLTFResult = GLTF & {
  nodes: {
    Cube: THREE.Mesh
  }
  materials: {
    Material: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[]
}

// type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function Boogie3D2(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('3d/product_boogie_4.glb') as GLTFResult
  return (
    <motion.group {...props} dispose={null}>
      <motion.mesh geometry={nodes.Cube.geometry} material={materials.Material} scale={[0.5, 0.76, 0.026]} />
    </motion.group>
  )
}

useGLTF.preload('3d/product_boogie_4.glb')
