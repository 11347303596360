// @ts-nocheck
import React, { useState } from 'react';
import { Variants, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { PAGE_HEAD } from '../../misc/ids';

interface IHeaderHamburgerItemProps {
    route: string,
    onTouchSubhandler: Function,
    subVariants: Variants,
    content: any
}

const LINE_VARIANTS: Variants = {
    initial: { width: "0px" },
    hovered: { width: "100%" }
}

const HeaderHamburgerItem: React.FC<IHeaderHamburgerItemProps> = ({ route, onTouchSubhandler, subVariants, content }) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
        <Link to={route}>
            <motion.li
                onClick={(e) => onTouchSubhandler()}
                className='cursor-pointer relative'
                variants={subVariants}
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <motion.p whileHover={{ scale: 1.05 }}>{content}</motion.p>
                {/* Line */}
                <motion.div
                    variants={LINE_VARIANTS}
                    className='bottom-0 right-0 h-1 bg-red-700 absolute'
                    initial={"initial"}
                    animate={hovered ? "hovered" : "initial"}
                />

            </motion.li>
        </Link>
    )
}

export default HeaderHamburgerItem;