import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react'
import Submenu from './Submenu';
import SubmenuOption from './SubmenuOption';
import { DiceProduct } from '../../../../../../../types/types';
import { useDispatch } from 'react-redux';
import { getStyle, setColor, setStyle } from '../../../../../../store/diceFilters';
import { useSelector } from 'react-redux';
import { getDiceProducts } from '../../../../../../store/products';


interface SubmenusProps {
    onSelect: Function,
    stylesOpen: boolean,
    colorsOpen: boolean
}

const Submenus: React.FC<SubmenusProps> = ({ onSelect, stylesOpen, colorsOpen }) => {
    const dispatch = useDispatch();
    const products = useSelector(getDiceProducts);
    const selectedStyle = useSelector(getStyle);

    const styles: string[] = useMemo(() => {
        const setStyles: Set<string> = new Set();
        for (let k in products) {
            if (!setStyles.has(products[k].style)) setStyles.add(products[k].style)
        }
        return Array.from(setStyles);
    }, [products])

    const filteredByStyle: DiceProduct[] = useMemo(() => {
        if (selectedStyle) {
            let d: DiceProduct[] = [];
            for (let k in products) {
                if (products[k].style === selectedStyle) d.push(products[k])
            }
        return d;
        } else {
            return []
        }
    }, [selectedStyle])

    return (
        < div className='flex size-full pointer-events-none absolute z-20'
            onClick={(e: React.MouseEvent<HTMLDivElement>) => { e.stopPropagation() }}
        >
            {/* Tint */}
            < motion.div
                className={'size-full inset-0 pointer-events-none bg-black absolute z-10'}
                animate={(stylesOpen || colorsOpen) ? { opacity: .6 } : { opacity: 0 }}
            />
            < div className='absolute top-0 right-0 z-30' >
                <AnimatePresence mode='popLayout'>
                    {stylesOpen && (
                        <Submenu
                            key={'styles'}
                        >
                            {styles.map((s: string) => <SubmenuOption key={s} onClick={(e: any) => { dispatch(setStyle(s)); onSelect() }}>{s}</SubmenuOption>)}
                        </Submenu>
                    )}
                    {colorsOpen && (
                        <Submenu
                            key={'colors'}
                        >
                            {filteredByStyle.map((p: DiceProduct) => <SubmenuOption key={p.serial} onClick={(e: any) => { dispatch(setColor(p.color)); onSelect() }}>{p.color}</SubmenuOption>)}
                        </Submenu>
                    )}
                </AnimatePresence>
            </div >
        </div >
    )
}

export default Submenus;