import React from 'react';
import BoogieCard from './LCDLifePad';
import SectionHeader from '../SectionHeader';

const BoogieSection: React.FC = () => {
    return (
        <div className='flex flex-col items-center gap-y-3'>
            <SectionHeader header='Boards'>
                Our <span className='font-semibold'>LCD Life Pad</span>, a sleek, ultra-thin tablet for doodling, jotting notes, and tracking your games.
            </SectionHeader>
            <BoogieCard />
        </div>
    )
}

export default BoogieSection;