import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDiceProducts } from '../../store/products';
import { getDicePreview, setDicePreview } from '../../store/preview';
import { useDispatch } from 'react-redux';

const PreviewOverlay: React.FC = () => {
    const [active, setActive] = useState<boolean>(false);
    const dispatch = useDispatch();
    const products = useSelector(getDiceProducts);
    const preview = useSelector(getDicePreview);

    useEffect(() => {
        preview && setActive(true);
    }, [preview])

    return (
        <div className='size-full absolute'>
            {active && (
                <div className='size-full flex justify-center items-center bg-slate-900 bg-opacity-80 hover:cursor-pointer fixed inset-0'
                    onClick={() => { setActive(false); dispatch(setDicePreview(''))}}
                >
                    <img className='min-w-[50%] shrink-0 hover:scale-125' src={products[preview].imageResource} />
                </div>
            )}
        </div>
    )
}

export default PreviewOverlay;