import React, { forwardRef, useCallback, useRef, useState } from "react";
import SVG_THG from "../SVG/SVG_THG";
import HeaderHamburger from "./HeaderHamburger";
import { Link, useLocation } from "react-router-dom";
import { Variants, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToastConstruction from "../Misc/ToastConstruction";
import { faMagnifyingGlass } from "@fortawesome/sharp-regular-svg-icons/faMagnifyingGlass";
import { faMapLocation } from "@fortawesome/sharp-regular-svg-icons/faMapLocation";
import { PAGE_HEAD } from "../../misc/ids";
import scrollIntoView from "scroll-into-view-if-needed";
import StickyHeadroom from '@integreat-app/react-sticky-headroom';
import useClickoutside from "../../hooks/useClickoutside";
// import HeaderCartButton from "./HeaderCartButton";


export const ICON_HOVER_VARIANTS: Variants = {
    animate: { scale: 1 },
    hovered: { scale: 1.05 }
}

const TAB_BAR_VARANTS: Variants = {
    initial: {
        width: "0px"
    },
    animate: ({ selected, hovered }) => {
        if (hovered) return selected ? { width: "100%" } : { width: "25%" }
        return selected ? { width: "100%" } : { width: "0px" }
    },
}

const Tab: React.FC<any> = forwardRef(({ children, dest }, pRef) => {
    const location = useLocation();
    const current = location.pathname;
    const [selected, setSelected] = useState<boolean>(current.startsWith(dest));
    const [hovered, setHovered] = useState<boolean>(false);

    const ref = useClickoutside(() => {
        current.startsWith(dest) || setSelected(false)
    })

    const handleOnClick = useCallback(() => {
        setSelected(true);
        const node = document.querySelector(`#${PAGE_HEAD}`)
        node && scrollIntoView(node);
    }, [])

    return (
        <Link
            ref={ref}
            to={dest}
            className="h-full hover:cursor-pointer flex items-center px-2"
            onClick={handleOnClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className="relative flex flex-col justify-center items-center">
                {children}
                <motion.div className="h-2 border-b border-b-slate-700 absolute mt-1 bottom-0"
                    layout
                    variants={TAB_BAR_VARANTS}
                    initial="initial"
                    animate="animate"
                    custom={{ selected: selected || current.startsWith(dest), hovered }}
                />
            </div>
        </Link>
    )
})

const Header: React.FC = () => {
    const [menuActive, setMenuActive] = useState<boolean>(false);
    const menuRef = useRef(null!);
    const headerRef = useRef<any>(null!);

    return (
        <StickyHeadroom scrollHeight={96}>
            <header
                ref={headerRef}
            >
                <div className="flex flex-col lg:hidden">
                    <div className="w-full h-12 flex justify-center items-center bg-white">
                        <div className="h-10 w-10 mt-px">
                            <SVG_THG className="size-full" colorFill="red" />
                        </div>
                        <Link to={"/"}>
                            <p className="font-semibold mt-px text-3xl text-teko text-red-600 align-bottom">TRYHARD GAMING</p>
                        </Link>
                    </div>
                    <div className="w-full h-12 px-2 bg-red-600 text-white flex justify-between items-center">
                        <div />

                        <div className="flex gap-x-4">
                            {/* <Link to={'/cart'}>
                                <HeaderCartButton className="text-2xl hover:cursor-pointer mt-1 text-white" />
                            </Link> */}
                            <ToastConstruction>
                                <motion.div
                                    variants={ICON_HOVER_VARIANTS}
                                    animate="animate"
                                    whileHover="hovered"
                                >
                                    <FontAwesomeIcon className="text-2xl hover:cursor-pointer mt-1" icon={faMapLocation} />
                                </motion.div>

                            </ToastConstruction>
                            <HeaderHamburger />
                        </div>
                    </div>
                </div>

                {/* Large Menu */}
                <div className="hidden w-full lg:flex flex-col text-xl bg-white"
                    onMouseEnter={() => setMenuActive(true)}
                    onMouseLeave={() => setMenuActive(false)}
                    ref={menuRef}
                >

                    <motion.div className="w-full h-24 flex"
                        // variants={MENU_VARIANTS}
                        animate={menuActive ? "active" : "inactive"}
                    >
                        <div className="w-1/3 flex justify-center items-center pr-6">
                            <Tab dest={"/products"}>Products</Tab>
                            <Tab dest={"/contact"}>New Partners</Tab>
                            <Tab dest={"/about"}>About</Tab>
                        </div>

                        <div className="w-1/3 flex justify-center items-center gap-x-2">
                            <SVG_THG colorFill="#b91c1c" className="h-9" />
                            <Link to={"/"}>
                                <p className="font-semibold mt-1 text-5xl text-red-700 text-teko align-bottom">TRYHARD GAMING</p>
                            </Link>
                        </div>

                        <div className="w-1/3 flex justify-center items-center pr-6 gap-x-4">
                            {/* <HeaderCartButton className="text-3xl hover:cursor-pointer mt-1 text-red-800" /> */}
                            <ToastConstruction>
                                <motion.div
                                    variants={ICON_HOVER_VARIANTS}
                                    animate="animate"
                                    whileHover="hovered"
                                >
                                    <FontAwesomeIcon className="text-3xl hover:cursor-pointer mt-1 text-red-800" icon={faMagnifyingGlass} />
                                </motion.div>
                            </ToastConstruction>
                            <ToastConstruction>
                                <motion.div
                                    variants={ICON_HOVER_VARIANTS}
                                    animate="animate"
                                    whileHover="hovered"
                                >
                                    <FontAwesomeIcon className="text-3xl hover:cursor-pointer mt-1 text-red-800" icon={faMapLocation} />
                                </motion.div>

                            </ToastConstruction>
                        </div>
                    </motion.div>
                </div>
            </header>
        </StickyHeadroom>

    )
}

export default Header;