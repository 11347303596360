import React from 'react';
import PageFrame from '../PageFrame';
import CartList from './CartList';

const CartPage: React.FC = () => {
    return (
        <PageFrame>
            <div className='flex flex-col items-center gap-y-6 mb-12'>
                <p className="font-semibold  text-teko italic text-6xl text-red-950 max-w-fit">
                    Cart
                </p>
                <CartList />
                <button
                    type='button'
                    className='font-semibold text-teko italic text-3xl text-white hover:cursor-pointer rounded pl-2 pr-3 pt-2 pb-1 bg-red-800'
                >
                    Checkout
                </button>
            </div>
        </PageFrame>
    )
}

export default CartPage;