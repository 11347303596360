import React from 'react';
import ToastConstruction from '../Misc/ToastConstruction';

const NewsletterLayout: React.FC = () => {
    return (
        <div className='w-full h-96 flex flex-col justify-center items-center bg-red-950 text-white gap-y-2'>
            <p className='text-3xl italic text-teko font-semibold'>GET NOTIFIED WHEN OUR LATEST MERCH DROPS</p>
            <p className='text-xl'>Subscribe and stay stocked!</p>
            <ToastConstruction>
                <button type='button' className='bg-white text-red-600 text-teko text-3xl font-semibold rounded-sm pb-1 pt-2 px-2 shadow-2xl mt-2'>
                    Subscribe
                </button>
            </ToastConstruction>
        </div>
    )
}

export default NewsletterLayout;