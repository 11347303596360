// @ts-nocheck
import React, { useState } from "react";
import { AnimatePresence, Variants, motion } from "framer-motion";
import useDebounce from "../../hooks/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronsDown } from "@fortawesome/pro-duotone-svg-icons/faChevronsDown";
import { PAGE_HEAD } from "../../misc/ids";
import SVG_THG from "../SVG/SVG_THG";

const IMAGE_URLS = [
    '\\image\\igorovsyannykov.jpg',
    '\\image\\julius_drost.jpg',
    '\\image\\mitaukano.jpg',
    '\\image\\ryan_quintal.jpg',
    '\\image\\vinsky2002.jpg'
]

const getNextCursor = (i: number) => {
    return (1 + i) % IMAGE_URLS.length;
}

// ms
const COMPONENT_LIFECYCLE = 6000;

// s
const TRANSITIONAL_LIFECYCLE = COMPONENT_LIFECYCLE * .25
const MIDDLE_LIFECYCLE = COMPONENT_LIFECYCLE * .5;

const IMAGE_VARIANTS: Variants = {
    fadeIn: {
        opacity: 0,
        x: -20,
        scale: 1.1,
    },
    neutral: {
        opacity: 1,
        x: 0,
        transition: {
            duration: MIDDLE_LIFECYCLE / 1000
        }
    },
    fadeOut: {
        opacity: 0,
        x: 20,
        transition: {
            duration: TRANSITIONAL_LIFECYCLE / 1000
        }
    }
}

const MainHero: React.FC = () => {
    const [cursor, setCursor] = useState<number>(0);

    useDebounce(() => { setCursor(getNextCursor(cursor)) }, COMPONENT_LIFECYCLE, [cursor])

    return (
        <div className="w-full h-dvh overflow-clip relative">
            <motion.div className="w-full min-h-96 h-[92.5%] mb-2 relative overflow-clip flex justify-center items-center"
                exit={{ opacity: 0 }}
            >
                {/* Background Image */}
                <AnimatePresence initial={false} mode="popLayout">
                    <motion.img
                        className="object-cover size-full min-h-96"
                        src={IMAGE_URLS[cursor]}
                        variants={IMAGE_VARIANTS}
                        initial="fadeIn"
                        animate="neutral"
                        exit="fadeOut"
                        key={cursor}
                        layout
                    />
                </AnimatePresence>


                {/* Background Gradient */}
                <div className=" size-full transform bg-gradient-to-b from-black/50 via-red-700/50 to-red-700/50 absolute" />

                {/* Content */}
                <div className=" size-full flex flex-col items-center absolute">
                    <div className="h-1/5 flex items-center">
                        <p className="text-teko italic text-5xl text-white opacity-90">Quality Game Gear</p>
                    </div>
                    <div className="h-3/5 w-full px-4 opacity-50">
                        <SVG_THG className="size-full px-2" colorFill="white" />
                    </div>
                    {/* Button */}
                    <div className="h-1/5 flex justify-center items-center z-20">
                        <motion.a
                            // className="mb-5"
                            href={`#${PAGE_HEAD}`}
                            whileHover={{ scale: 1.1, cursor: "pointer" }}
                            onClick={(e) => { e.preventDefault(); document.querySelector(`#${PAGE_HEAD}`)?.scrollIntoView({
                                behavior: 'smooth'
                            }) }}
                        >
                            <FontAwesomeIcon className=" text-white text-4xl" icon={faChevronsDown} />
                        </motion.a>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default MainHero;