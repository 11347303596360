import React from 'react';
import { motion } from "framer-motion"
import { SMOOTH_IN_OUT_VARIANTS } from "../../../../variants"

const SectionHeader: React.FC<{ children: any, header: string }> = ({ children, header }) => {
    return (
        <motion.div
            variants={SMOOTH_IN_OUT_VARIANTS}
            initial='initial'
            animate='animate'
            exit='exit'
            layout
            className='flex flex-col items-center'
        >
            <div className='pb-8'>
                <p className="font-semibold  text-teko italic text-6xl text-red-950 max-w-fit">
                    {header}
                </p>
                <div className="border-b-4 border-red-950 h-1 w-full" />
            </div>
            <p className='text-xl text-center px-20'>
                {children}
            </p>
        </motion.div>
    )
}

export default SectionHeader;