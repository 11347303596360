import React from 'react';
import { motion } from 'framer-motion';
import BackArrow from '../../Misc/BackArrow';
import { useLocation } from 'react-router-dom';
import { SMOOTH_IN_OUT_VARIANTS } from '../../../variants';

const PageFrame: React.FC<any> = ({ children }) => {
    const location = useLocation();

    return (
        <motion.div
            className='relative'
            variants={SMOOTH_IN_OUT_VARIANTS}
            initial="initial"
            animate="animate"
            exit="exit"
            layout
        >
            {children}

            {
                (location.pathname.length > 1) && (
                    <div className='absolute top-0 right-0 left-0 bottom-0 pointer-events-none flex justify-end items-center'>
                        <motion.div
                            className='pointer-events-auto'
                            layout
                        // initial={{ opacity: 0, alignSelf: "center" }}
                        // animate={{ opacity: 1, alignSelf: "flex-start" }}
                        >
                            <BackArrow />
                        </motion.div>
                    </div>
                )
            }
        </motion.div>
    )
}

export default PageFrame;