import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/sharp-regular-svg-icons/faWrench';
import { AnimatePresence, motion } from 'framer-motion';
import React, { MouseEventHandler, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';

const VARIANTS = {
    "initial": {
        opacity: 0,
        y: 0
    },
    "neutral": {
        opacity: 1,
        y: -10
    },
    "exit": {
        opacity: 0,
        y: -30,
        transition: {
            duration: .1
        }
    }
}

const ToastConstruction: React.FC<any> = ({ children }) => {
    const [toasting, setToasting] = useState<boolean>(false);

    useDebounce(() => { setToasting(false) }, 3000, [toasting])

    const clickHandler: MouseEventHandler<HTMLDivElement> = (e): void => { setToasting(true) }

    return (
        <div className=' relative'
            onClick={clickHandler}
        >
            {children}
            <AnimatePresence>
                {toasting && (
                    <motion.div
                        className="w-full flex bg-pink-600 justify-center top-0 left-0 absolute"
                        variants={VARIANTS}
                        key={1}
                        initial="initial"
                        animate="neutral"
                        exit="exit"
                        transition={{ duration: 2, type: "tween", ease: "easeOut" }}
                    >
                        <div className='absolute flex justify-center top-0'>
                            <div
                                className='min-w-max max-w-fit rounded-sm bg-gray-800 text-gray-300 text-teko px-2 py-1 flex justify-center items-center -bottom-full absolute'
                            >
                                <div className='size-6 -z-10 rotate-45 top-1/2 bg-gray-800 absolute' />

                                <FontAwesomeIcon className='text-lg text-gray-300 mr-2' icon={faWrench} />
                                <p className='text-2xl'>
                                    Coming Soon!
                                </p>
                            </div>
                        </div>

                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default ToastConstruction;