import React from "react";
import ToastConstruction from "../Misc/ToastConstruction";
import FooterItem from "./FooterItem";

const Footer: React.FC = () => {

    return (
        <footer className="w-full py-12 bg-red-700 text-white text-2xl grid grid-cols-2 md:grid-cols-4 gap-y-4 px-4">
            {/* Need help? */}
            <div className="flex flex-col items-center md:items-start md:px-8">
                <div>
                    <h6 className="text-3xl text-teko italic font-semibold">Need help?</h6>
                    <ul className="flex flex-col gap-y-2 mt-2 text-lg">
                        <li>
                            <ToastConstruction>
                                <FooterItem route='/faq' content='FAQ' comingSoon={true} />
                            </ToastConstruction>
                        </li>
                        <li>
                            <ToastConstruction>
                                <FooterItem route='/service' content='Customer Service' comingSoon={true} />
                            </ToastConstruction>
                        </li>
                        <li>
                            <FooterItem route='/contact' content='Contact' comingSoon={false} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Legal */}
            <div className="flex flex-col items-center md:items-start md:px-12">
                <div>
                    <h6 className="text-3xl text-teko italic font-semibold">Legal</h6>
                    <ul className="flex flex-col gap-y-2 mt-2 text-lg">
                        <li>
                            <ToastConstruction>
                                <FooterItem route='/privacy' content='Privacy Notice' comingSoon={true} />
                            </ToastConstruction>
                        </li>
                        <li>
                            <ToastConstruction>
                                <FooterItem route='/cancellations' content='Cancellations' comingSoon={true} />
                            </ToastConstruction>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Retail */}
            <div className="flex flex-col items-center md:items-start md:px-12">
                <div>
                    <h6 className="text-3xl text-teko italic font-semibold">Retail</h6>
                    <ul className="flex flex-col gap-y-2 mt-2 text-lg">
                        <li>
                            <ToastConstruction>
                                <FooterItem route='/partners' content='Our Partners' comingSoon={true} />
                            </ToastConstruction>
                        </li>
                        <li>
                            <ToastConstruction>
                                <FooterItem route='/locations' content='Store Finder' comingSoon={true} />
                            </ToastConstruction>
                        </li>

                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default React.memo(Footer);