import React from 'react';
import { useSelector } from 'react-redux';
import { getSelection, getStyle } from '../../../../../../store/diceFilters';
import { getStylesToColors } from '../../../../../../store/meta';
import { getDiceProducts } from '../../../../../../store/products';

interface ExtraInfoProps {
    colorsCursor: number,
    stylesCursor: number
}

const ExtraInfo: React.FC<ExtraInfoProps> = ({ colorsCursor, stylesCursor }) => {
    const serial = useSelector(getSelection);
    const products = useSelector(getDiceProducts);
    const style = useSelector(getStyle);
    const stylesToColors = useSelector(getStylesToColors);

    const product = products[serial];

    return (
        <div className='flex flex-col w-full text-lg text-slate-100'>
            <div className="w-full flex justify-center items-center h-10 bg-red-500 select-none">
                {(stylesToColors[style]) ? (
                    <>
                        <div className="grow max-w-[50%] h-full flex items-center justify-end pr-2">
                            <p className=''>Size: {product.size ? `${product.size}mm` : 'Collection'}</p>
                        </div>
                        <div>|</div>
                        <div className="grow max-w-[50%] h-full flex items-center pt-1 font-mono">
                            <div className='grid grid-cols-3 ml-2'>
                                <div>{colorsCursor + 1}</div>
                                <div className='pb-1'>/</div>
                                <div>{stylesToColors[style].length}</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>—</div>
                )}
            </div>
            <div className="w-full h-10 bg-red-700 flex justify-center items-center font-mono">
                {product ? (
                    <>
                        <div className="grow max-w-[50%] h-full flex items-center justify-end pr-1">
                            <p className=''>{product.serial}</p>
                        </div>
                        <div className='font-sans pb-1'>|</div>
                        <div className="grow max-w-[50%] h-full flex items-center pl-1">
                            <p className='italic'>SKU: {product.sku || '—'}</p>
                        </div>
                    </>
                ) : (
                    <div>—</div>
                )}
            </div>
        </div>
    )
}

export default ExtraInfo;