import React from "react";
import CategoryCard from "./CategoryCard";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ToastConstruction from "../../../Misc/ToastConstruction";
import PageFrame from "../PageFrame";

const PageHome: React.FC = () => {


    return (
        <PageFrame>
            <div className='flex flex-col justify-between items-center gap-y-12 pb-12'>

                <div className="flex flex-col gap-y-12 max-w-[576px]">
                    <div className="self-center md:self-start">
                        <p className="font-semibold  text-teko italic text-6xl text-red-950 max-w-fit">
                            What We Do
                        </p>
                        <div className="border-b-4 border-red-950 h-1 w-full" />
                    </div>
                    <div className="flex flex-col justify-center items-center gap-y-12 md:flex-row">
                        <CategoryCard url={"/products"} imgUrl="image\black_boogie_board_1.png" icon="faTablet" caption="OUR PRODUCTS" />
                        <ToastConstruction>
                            <CategoryCard url={"/"} imgUrl={"image\\tima-miroshnichenko.jpg"} icon="faTruck" caption="OUR SERVICES" />
                        </ToastConstruction>
                    </div>

                    <div className="flex flex-col items-center gap-y-2 w-full">
                        <p className="text-teko text-5xl text-red-950">Professional vendor for...</p>

                        <Link className="self-center flex" to="https://www.comic-con.org/">
                            <img className="w-full" src="image\comiccon_logo.png" />
                        </Link>
                    </div>
                </div>



                <div className="text-center text-teko text-4xl text-red-950">

                    Try the Tryhard experience.&#160;
                    <br className="md:hidden" />
                    <Link className="text-red-500" to="/contact">
                        <motion.span
                            className="text-red-500"
                            whileHover={{
                                color: "rgb(253 224 71)"
                            }}
                        >
                            Contact Us
                        </motion.span>
                    </Link>
                </div>

            </div>
        </PageFrame>


    )
}

export default React.memo(PageHome);