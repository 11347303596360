import React, { useMemo } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import MainHero from './MainHero';
import NewsletterLayout from "./NewsletterLayout";
import { PAGE_HEAD } from "../../misc/ids";

import PageHome from "./Pages/Home/HomePage";

import AboutPage from "./Pages/About/AboutPage";
import ContactPage from "./Pages/Contact/ContactPage";
import ProductPage from "./Pages/Products/ProductPage";

import CartPage from "./Pages/Cart/CartPage";

const Main: React.FC = () => {
    const location = useLocation();
    const locationArr = location.pathname?.split("/") ?? [];

    const Homepage = useMemo(() => <PageHome />, [])

    return (
        <main className="flex flex-col grow">
            <MainHero />
            <a id={`${PAGE_HEAD}`} />
            <AnimatePresence initial={false} mode="wait">
                <Routes location={location} key={locationArr[1]}>
                    <Route path="/" element={Homepage} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/products" element={<ProductPage />}>
                    </Route>
                    <Route path='/cart' element={<CartPage />} />
                    <Route path="/*" element={Homepage} />
                </Routes>
            </AnimatePresence>
            <NewsletterLayout />
        </main>
    )
}

export default Main;