import { motion, Variants } from 'framer-motion';
import React from 'react';
import { DiceProduct } from '../../../../../../../types/types';
import { useDispatch } from 'react-redux';
import { setSelection } from '../../../../../../store/diceFilters';

const CarouselItemVariants: Variants = {
    animate: {
        scale: .9
    },
    sideline: {
        scale: .8
    },
    whileHover: {
        scale: .95
    }
}

interface CarouselItemProps {
    spotlight: boolean,
    product: DiceProduct,
    onSelect: Function
}

const CarouselItem: React.FC<CarouselItemProps> = ({ spotlight, product, onSelect }) => {
    const dispatch = useDispatch();
    const handleOnClick = () => {
        dispatch(setSelection(product.serial));
        onSelect();
    }

    return (
        <motion.div
            className='h-4/5 aspect-video w-full shrink-0 bg-red-50 bg-opacity-20 bg-no-repeat'
            onClick={handleOnClick}
            style={{
                backgroundImage: `url(${product.imageResource})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center'
            }}
            variants={CarouselItemVariants}
            animate={spotlight ? 'animate' : 'sideline'}
            whileHover='whileHover'
            layout
        />
    )
}

export default CarouselItem;