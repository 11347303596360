import { DiceProduct } from "../../types/types";

export const allDice: { [key: string]: DiceProduct } = {
    "mgd6-12001": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12001",
      "color": "white",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12001.png"
    },
    "mgd6-12002": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12002",
      "color": "bold",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12002.png"
    },
    "mgd6-12003": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12003",
      "color": "chalice",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12003.png"
    },
    "mgd6-12004": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12004",
      "color": "classic",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12001.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12004.png"
    },
    "mgd6-12005": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12005",
      "color": "granite",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12014.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12005.png"
    },
    "mgd6-12006": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12006",
      "color": "black",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12006.png"
    },
    "mgd6-12007": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12007",
      "color": "pink",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12007.png"
    },
    "mgd6-12008": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12008",
      "color": "purple",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12005.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12008.png"
    },
    "mgd6-12009": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12009",
      "color": "sky",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12006.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12009.png"
    },
    "mgd6-12010": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12010",
      "color": "easter",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12007.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12010.png"
    },
    "mgd6-12011": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12011",
      "color": "yellowjacket",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12010.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12011.png"
    },
    "mgd6-12012": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12012",
      "color": "mermalade",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12008.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12012.png"
    },
    "mgd6-12013": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12013",
      "color": "lemon",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12011.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12013.png"
    },
    "mgd6-12014": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12014",
      "color": "tangerine",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12014.png"
    },
    "mgd6-12015": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12015",
      "color": "posh",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12015.png"
    },
    "mgd6-12016": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12016",
      "color": "refurbished",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12016.png"
    },
    "mgd6-12017": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12017",
      "color": "chocolate",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12017.png"
    },
    "mgd6-12018": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12018",
      "color": "steel",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12018.png"
    },
    "mgd6-12019": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12019",
      "color": "blanky",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12019.png"
    },
    "mgd6-12020": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12020",
      "color": "ocean",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12020.png"
    },
    "mgd6-12021": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12021",
      "color": "lucky",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-o12009.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12021.png"
    },
    "mgd6-12022": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12022",
      "color": "orange",
      "style": "opaque",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12022.png"
    },
    "mgd6-12023": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12023",
      "color": "smoke",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12005.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12023.png"
    },
    "mgd6-12024": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12024",
      "color": "sea",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12005.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12024.png"
    },
    "mgd6-12025": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12025",
      "color": "grape",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12001.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12025.png"
    },
    "mgd6-12026": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12026",
      "color": "mint",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12007.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12026.png"
    },
    "mgd6-12027": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12027",
      "color": "drops",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12008.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12027.png"
    },
    "mgd6-12028": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12028",
      "color": "dragonfruit",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12002.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12028.png"
    },
    "mgd6-12029": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12029",
      "color": "lime",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-t12003.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12029.png"
    },
    "mgd6-12030": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12030",
      "color": "prism",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12030.png"
    },
    "mgd6-12031": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12031",
      "color": "strawberry",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12031.png"
    },
    "mgd6-12032": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12032",
      "color": "orange",
      "style": "transparent",
      "size": 12,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/12mmExcel/mgd6-12032.png"
    },
    "mgd6-12033": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12033",
      "color": "slate",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12033.png"
    },
    "mgd6-12034": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12034",
      "color": "vine",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12001.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12034.png"
    },
    "mgd6-12035": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12035",
      "color": "nyc",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12006.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12035.png"
    },
    "mgd6-12036": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12036",
      "color": "juice",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12003.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12036.png"
    },
    "mgd6-12037": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12037",
      "color": "honey",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12002.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12037.png"
    },
    "mgd6-12038": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12038",
      "color": "taffy",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12008.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12038.png"
    },
    "mgd6-12039": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12039",
      "color": "bonnet",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12009.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12039.png"
    },
    "mgd6-12040": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12040",
      "color": "mulberry",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12010.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12040.png"
    },
    "mgd6-12041": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12041",
      "color": "blade",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12041.png"
    },
    "mgd6-12042": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12042",
      "color": "amber",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12011.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12042.png"
    },
    "mgd6-12043": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12043",
      "color": "watermelon",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12012.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12043.png"
    },
    "mgd6-12044": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12044",
      "color": "cobalt",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12004.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12044.png"
    },
    "mgd6-12045": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12045",
      "color": "ochre",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12013.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12045.png"
    },
    "mgd6-12046": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12046",
      "color": "sweet",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12014.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12046.png"
    },
    "mgd6-12047": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12047",
      "color": "brick",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12005.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12047.png"
    },
    "mgd6-12048": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12048",
      "color": "casino",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12048.png"
    },
    "mgd6-12049": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12049",
      "color": "candy",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12049.png"
    },
    "mgd6-12050": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12050",
      "color": "cave",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-p12015.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12050.png"
    },
    "mgd6-12051": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12051",
      "color": "money",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12051.png"
    },
    "mgd6-12052": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12052",
      "color": "violet",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12052.png"
    },
    "mgd6-12053": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12053",
      "color": "summer",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12053.png"
    },
    "mgd6-12054": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12054",
      "color": "jungle",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12054.png"
    },
    "mgd6-12055": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12055",
      "color": "lake",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12055.png"
    },
    "mgd6-12056": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12056",
      "color": "volcano",
      "style": "pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12056.png"
    },
    "mgd6-12057": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12057",
      "color": "ash",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1203.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12057.png"
    },
    "mgd6-12058": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12058",
      "color": "gatherer",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1204.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12058.png"
    },
    "mgd6-12059": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12059",
      "color": "hunter",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1205.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12059.png"
    },
    "mgd6-12060": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12060",
      "color": "anima",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1206.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12060.png"
    },
    "mgd6-12061": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12061",
      "color": "witchcraft",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1202.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12061.png"
    },
    "mgd6-12062": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12062",
      "color": "nimbus",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1202.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12062.png"
    },
    "mgd6-12063": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12063",
      "color": "root",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "imageResource": "dice/12mmExcel/mgd6-12063.png"
    },
    "mgd6-12064": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12064",
      "color": "butterscotch",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1207.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12064.png"
    },
    "mgd6-12065": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12065",
      "color": "pillow",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1208.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12065.png"
    },
    "mgd6-12066": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-12066",
      "color": "factory",
      "style": "double pearl",
      "size": 12,
      "setQuantity": 36,
      "generatedImageResource": "mgd6-dc1201.jpg",
      "imageResource": "dice/12mmExcel/mgd6-12066.png"
    },
    "mgd6-001": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-001",
      "color": "TBD-1",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-001.jpg"
    },
    "mgd6-002": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-002",
      "color": "TBD-2",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-002.jpg"
    },
    "mgd6-003": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-003",
      "color": "TBD-3",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-003.jpg"
    },
    "mgd6-004": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-004",
      "color": "TBD-4",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-004.jpg"
    },
    "mgd6-005": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-005",
      "color": "TBD-5",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-005.jpg"
    },
    "mgd6-006": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-006",
      "color": "TBD-6",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-006.jpg"
    },
    "mgd6-007": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-007",
      "color": "TBD-7",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-007.jpg"
    },
    "mgd6-008": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-008",
      "color": "TBD-8",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-008.jpg"
    },
    "mgd6-009": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-009",
      "color": "TBD-9",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-009.jpg"
    },
    "mgd6-010": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-010",
      "color": "TBD-10",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-010.jpg"
    },
    "mgd6-011": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-011",
      "color": "TBD-11",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-011.jpg"
    },
    "mgd6-012": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-012",
      "color": "TBD-12",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-012.jpg"
    },
    "mgd6-013": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-013",
      "color": "TBD-13",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-013.jpg"
    },
    "mgd6-014": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-014",
      "color": "TBD-14",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-014.jpg"
    },
    "mgd6-015": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-015",
      "color": "TBD-15",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-015.jpg"
    },
    "mgd6-016": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-016",
      "color": "TBD-16",
      "style": "opaque",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-016.jpg"
    },
    "mgd6-017": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-017",
      "color": "TBD-17",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-017.jpg"
    },
    "mgd6-018": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-018",
      "color": "TBD-18",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-018.jpg"
    },
    "mgd6-019": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-019",
      "color": "TBD-19",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-019.jpg"
    },
    "mgd6-020": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-020",
      "color": "TBD-20",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-020.jpg"
    },
    "mgd6-021": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-021",
      "color": "TBD-21",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-021.jpg"
    },
    "mgd6-022": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-022",
      "color": "TBD-22",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-022.jpg"
    },
    "mgd6-023": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-023",
      "color": "TBD-23",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-023.jpg"
    },
    "mgd6-024": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-024",
      "color": "TBD-24",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-024.jpg"
    },
    "mgd6-025": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-025",
      "color": "TBD-25",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-025.jpg"
    },
    "mgd6-026": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-026",
      "color": "TBD-26",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-026.jpg"
    },
    "mgd6-027": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-027",
      "color": "TBD-27",
      "style": "opaque",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-027.jpg"
    },
    "mgd6-028": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-028",
      "color": "TBD-28",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-028.jpg"
    },
    "mgd6-029": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-029",
      "color": "TBD-29",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-029.jpg"
    },
    "mgd6-030": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-030",
      "color": "TBD-30",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-030.jpg"
    },
    "mgd6-031": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-031",
      "color": "TBD-31",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-031.jpg"
    },
    "mgd6-032": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-032",
      "color": "TBD-32",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-032.jpg"
    },
    "mgd6-033": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-033",
      "color": "TBD-33",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-033.jpg"
    },
    "mgd6-034": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-034",
      "color": "TBD-34",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-034.jpg"
    },
    "mgd6-035": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-035",
      "color": "TBD-35",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-035.jpg"
    },
    "mgd6-036": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-036",
      "color": "TBD-36",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-036.jpg"
    },
    "mgd6-037": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-037",
      "color": "TBD-37",
      "style": "educational",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-037.jpg"
    },
    "mgd6-038": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-038",
      "color": "TBD-38",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-038.jpg"
    },
    "mgd6-039": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-039",
      "color": "TBD-39",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-039.jpg"
    },
    "mgd6-040": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-040",
      "color": "TBD-40",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-040.jpg"
    },
    "mgd6-041": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-041",
      "color": "TBD-41",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-041.jpg"
    },
    "mgd6-042": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-042",
      "color": "TBD-42",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-042.jpg"
    },
    "mgd6-043": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-043",
      "color": "TBD-43",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-043.jpg"
    },
    "mgd6-044": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-044",
      "color": "TBD-44",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-044.jpg"
    },
    "mgd6-045": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-045",
      "color": "TBD-45",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-045.jpg"
    },
    "mgd6-046": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-046",
      "color": "TBD-46",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-046.jpg"
    },
    "mgd6-047": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-047",
      "color": "TBD-47",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-047.jpg"
    },
    "mgd6-048": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-048",
      "color": "TBD-48",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-048.jpg"
    },
    "mgd6-049": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-049",
      "color": "TBD-49",
      "style": "transparent",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-049.jpg"
    },
    "mgd6-050": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-050",
      "color": "TBD-50",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-050.jpg"
    },
    "mgd6-051": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-051",
      "color": "TBD-51",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-051.jpg"
    },
    "mgd6-052": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-052",
      "color": "TBD-52",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-052.jpg"
    },
    "mgd6-053": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-053",
      "color": "TBD-53",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-053.jpg"
    },
    "mgd6-054": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-054",
      "color": "TBD-54",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-054.jpg"
    },
    "mgd6-055": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-055",
      "color": "TBD-55",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-055.jpg"
    },
    "mgd6-056": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-056",
      "color": "TBD-56",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-056.jpg"
    },
    "mgd6-057": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-057",
      "color": "TBD-57",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-057.jpg"
    },
    "mgd6-058": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-058",
      "color": "TBD-58",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-058.jpg"
    },
    "mgd6-059": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-059",
      "color": "TBD-59",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-059.jpg"
    },
    "mgd6-060": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-060",
      "color": "TBD-60",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-060.jpg"
    },
    "mgd6-061": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-061",
      "color": "TBD-61",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-061.jpg"
    },
    "mgd6-062": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-062",
      "color": "TBD-62",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-062.jpg"
    },
    "mgd6-063": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-063",
      "color": "TBD-63",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-063.jpg"
    },
    "mgd6-064": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-064",
      "color": "TBD-64",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-064.jpg"
    },
    "mgd6-065": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-065",
      "color": "TBD-65",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-065.jpg"
    },
    "mgd6-066": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-066",
      "color": "TBD-66",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-066.jpg"
    },
    "mgd6-067": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-067",
      "color": "TBD-67",
      "style": "transparent",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-067.jpg"
    },
    "mgd6-068": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-068",
      "color": "TBD-68",
      "style": "glow",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-068.jpg"
    },
    "mgd6-069": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-069",
      "color": "TBD-69",
      "style": "glow",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-069.jpg"
    },
    "mgd6-070": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-070",
      "color": "TBD-70",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-070.jpg"
    },
    "mgd6-071": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-071",
      "color": "TBD-71",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-071.jpg"
    },
    "mgd6-072": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-072",
      "color": "TBD-72",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-072.jpg"
    },
    "mgd6-073": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-073",
      "color": "TBD-73",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-073.jpg"
    },
    "mgd6-074": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-074",
      "color": "TBD-74",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-074.jpg"
    },
    "mgd6-075": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-075",
      "color": "TBD-75",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-075.jpg"
    },
    "mgd6-076": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-076",
      "color": "TBD-76",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-076.jpg"
    },
    "mgd6-077": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-077",
      "color": "TBD-77",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-077.jpg"
    },
    "mgd6-078": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-078",
      "color": "TBD-78",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-078.jpg"
    },
    "mgd6-079": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-079",
      "color": "TBD-79",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-079.jpg"
    },
    "mgd6-080": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-080",
      "color": "TBD-80",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-080.jpg"
    },
    "mgd6-081": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-081",
      "color": "TBD-81",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-081.jpg"
    },
    "mgd6-082": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-082",
      "color": "TBD-82",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-082.jpg"
    },
    "mgd6-083": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-083",
      "color": "TBD-83",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-083.jpg"
    },
    "mgd6-084": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-084",
      "color": "TBD-84",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-084.jpg"
    },
    "mgd6-085": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-085",
      "color": "TBD-85",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-085.jpg"
    },
    "mgd6-086": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-086",
      "color": "TBD-86",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-086.jpg"
    },
    "mgd6-087": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-087",
      "color": "TBD-87",
      "style": "pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-087.jpg"
    },
    "mgd6-088": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-088",
      "color": "TBD-88",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-088.jpg"
    },
    "mgd6-089": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-089",
      "color": "TBD-89",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-089.jpg"
    },
    "mgd6-090": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-090",
      "color": "TBD-90",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-090.jpg"
    },
    "mgd6-091": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-091",
      "color": "TBD-91",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-091.jpg"
    },
    "mgd6-092": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-092",
      "color": "TBD-92",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-092.jpg"
    },
    "mgd6-093": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-093",
      "color": "TBD-93",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-093.jpg"
    },
    "mgd6-094": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-094",
      "color": "TBD-94",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-094.jpg"
    },
    "mgd6-095": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-095",
      "color": "TBD-95",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-095.jpg"
    },
    "mgd6-096": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-096",
      "color": "TBD-96",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-096.jpg"
    },
    "mgd6-097": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-097",
      "color": "TBD-97",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-097.jpg"
    },
    "mgd6-098": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-098",
      "color": "TBD-98",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-098.jpg"
    },
    "mgd6-099": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-099",
      "color": "TBD-99",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-099.jpg"
    },
    "mgd6-100": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-100",
      "color": "TBD-100",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-100.jpg"
    },
    "mgd6-101": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-101",
      "color": "TBD-101",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-101.jpg"
    },
    "mgd6-102": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-102",
      "color": "TBD-102",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-102.jpg"
    },
    "mgd6-103": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-103",
      "color": "TBD-103",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-103.jpg"
    },
    "mgd6-104": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-104",
      "color": "TBD-104",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-104.jpg"
    },
    "mgd6-105": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-105",
      "color": "TBD-105",
      "style": "pearl",
      "shape": "square",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "numeral"
      ],
      "imageResource": "dice/16mm/mgd6-105.jpg"
    },
    "mgd6-106": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-106",
      "color": "TBD-106",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-106.jpg"
    },
    "mgd6-107": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-107",
      "color": "TBD-107",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-107.jpg"
    },
    "mgd6-108": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-108",
      "color": "TBD-108",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-108.jpg"
    },
    "mgd6-109": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-109",
      "color": "TBD-109",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-109.jpg"
    },
    "mgd6-110": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-110",
      "color": "TBD-110",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-110.jpg"
    },
    "mgd6-111": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-111",
      "color": "TBD-111",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-111.jpg"
    },
    "mgd6-112": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-112",
      "color": "TBD-112",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-112.jpg"
    },
    "mgd6-113": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-113",
      "color": "TBD-113",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-113.jpg"
    },
    "mgd6-114": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-114",
      "color": "TBD-114",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-114.jpg"
    },
    "mgd6-115": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-115",
      "color": "TBD-115",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-115.jpg"
    },
    "mgd6-116": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-116",
      "color": "TBD-116",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-116.jpg"
    },
    "mgd6-117": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-117",
      "color": "TBD-117",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-117.jpg"
    },
    "mgd6-118": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-118",
      "color": "TBD-118",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-118.jpg"
    },
    "mgd6-119": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-119",
      "color": "TBD-119",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-119.jpg"
    },
    "mgd6-120": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-120",
      "color": "TBD-120",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-120.jpg"
    },
    "mgd6-121": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-121",
      "color": "TBD-121",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-121.jpg"
    },
    "mgd6-122": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-122",
      "color": "TBD-122",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "imageResource": "dice/16mm/mgd6-122.jpg"
    },
    "mgd6-123": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-123",
      "color": "TBD-123",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-123.jpg"
    },
    "mgd6-124": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-124",
      "color": "TBD-124",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-124.jpg"
    },
    "mgd6-125": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-125",
      "color": "TBD-125",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-125.jpg"
    },
    "mgd6-126": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-126",
      "color": "TBD-126",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-126.jpg"
    },
    "mgd6-127": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-127",
      "color": "TBD-127",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-127.jpg"
    },
    "mgd6-128": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-128",
      "color": "TBD-128",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-128.jpg"
    },
    "mgd6-129": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-129",
      "color": "TBD-129",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-129.jpg"
    },
    "mgd6-130": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "mgd6-130",
      "color": "TBD-130",
      "style": "double pearl",
      "shape": "round",
      "size": 16,
      "setQuantity": 36,
      "other": [
        "glitter"
      ],
      "imageResource": "dice/16mm/mgd6-130.jpg"
    },
    "AG-S-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-1",
      "sku": "",
      "color": "S-1",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-1.jpg"
    },
    "AG-S-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-10",
      "sku": "",
      "color": "S-10",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-10.jpg"
    },
    "AG-S-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-11",
      "sku": "",
      "color": "S-11",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-11.jpg"
    },
    "AG-S-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-12",
      "sku": "",
      "color": "S-12",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-12.jpg"
    },
    "AG-S-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-13",
      "sku": "",
      "color": "S-13",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-13.jpg"
    },
    "AG-S-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-14",
      "sku": "",
      "color": "S-14",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-14.jpg"
    },
    "AG-S-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-15",
      "sku": "",
      "color": "S-15",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-15.jpg"
    },
    "AG-S-16": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-16",
      "sku": "",
      "color": "S-16",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-16.jpg"
    },
    "AG-S-17": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-17",
      "sku": "",
      "color": "S-17",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-17.jpg"
    },
    "AG-S-18": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-18",
      "sku": "",
      "color": "S-18",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-18.jpg"
    },
    "AG-S-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-2",
      "sku": "",
      "color": "S-2",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-2.jpg"
    },
    "AG-S-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-3",
      "sku": "",
      "color": "S-3",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-3.jpg"
    },
    "AG-S-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-4",
      "sku": "",
      "color": "S-4",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-4.jpg"
    },
    "AG-S-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-5",
      "sku": "",
      "color": "S-5",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-5.jpg"
    },
    "AG-S-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-6",
      "sku": "",
      "color": "S-6",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-6.jpg"
    },
    "AG-S-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-7",
      "sku": "",
      "color": "S-7",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-7.jpg"
    },
    "AG-S-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-8",
      "sku": "",
      "color": "S-8",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-8.jpg"
    },
    "AG-S-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-S-9",
      "sku": "",
      "color": "S-9",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/S-9.jpg"
    },
    "AG-T-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-1",
      "sku": "",
      "color": "T-1",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-1.jpg"
    },
    "AG-T-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-2",
      "sku": "",
      "color": "T-2",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-2.jpg"
    },
    "AG-T-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-3",
      "sku": "",
      "color": "T-3",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-3.jpg"
    },
    "AG-T-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-4",
      "sku": "",
      "color": "T-4",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-4.jpg"
    },
    "AG-T-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-5",
      "sku": "",
      "color": "T-5",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-5.jpg"
    },
    "AG-T-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-6",
      "sku": "",
      "color": "T-6",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-6.jpg"
    },
    "AG-T-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-7",
      "sku": "",
      "color": "T-7",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-7.jpg"
    },
    "AG-T-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "AG-T-8",
      "sku": "",
      "color": "T-8",
      "style": "AG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/AG/T-8.jpg"
    },
    "bonehand-B-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-1",
      "sku": "",
      "color": "B-1",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-1.jpg"
    },
    "bonehand-B-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-2",
      "sku": "",
      "color": "B-2",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-2.jpg"
    },
    "bonehand-B-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-3",
      "sku": "",
      "color": "B-3",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-3.jpg"
    },
    "bonehand-B-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-4",
      "sku": "",
      "color": "B-4",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-4.jpg"
    },
    "bonehand-B-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-5",
      "sku": "",
      "color": "B-5",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-5.jpg"
    },
    "bonehand-B-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-6",
      "sku": "",
      "color": "B-6",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-6.jpg"
    },
    "bonehand-B-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "bonehand-B-7",
      "sku": "",
      "color": "B-7",
      "style": "bonehand",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/bonehand/B-7.jpg"
    },
    "BSD-A-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "BSD-A-1",
      "sku": "",
      "color": "A-1",
      "style": "BSD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/BSD/A-1.jpg"
    },
    "BSD-A-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "BSD-A-2",
      "sku": "",
      "color": "A-2",
      "style": "BSD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/BSD/A-2.jpg"
    },
    "BSD-A-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "BSD-A-3",
      "sku": "",
      "color": "A-3",
      "style": "BSD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/BSD/A-3.jpg"
    },
    "BSD-A-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "BSD-A-4",
      "sku": "",
      "color": "A-4",
      "style": "BSD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/BSD/A-4.jpg"
    },
    "BSD-A-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "BSD-A-5",
      "sku": "",
      "color": "A-5",
      "style": "BSD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/BSD/A-5.jpg"
    },
    "CD-C-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-1",
      "sku": "",
      "color": "C-1",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-1.jpg"
    },
    "CD-C-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-10",
      "sku": "",
      "color": "C-10",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-10.jpg"
    },
    "CD-C-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-11",
      "sku": "",
      "color": "C-11",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-11.jpg"
    },
    "CD-C-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-12",
      "sku": "",
      "color": "C-12",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-12.jpg"
    },
    "CD-C-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-13",
      "sku": "",
      "color": "C-13",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-13.jpg"
    },
    "CD-C-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-2",
      "sku": "",
      "color": "C-2",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-2.jpg"
    },
    "CD-C-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-3",
      "sku": "",
      "color": "C-3",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-3.jpg"
    },
    "CD-C-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-4",
      "sku": "",
      "color": "C-4",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-4.jpg"
    },
    "CD-C-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-5",
      "sku": "",
      "color": "C-5",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-5.jpg"
    },
    "CD-C-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-6",
      "sku": "",
      "color": "C-6",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-6.jpg"
    },
    "CD-C-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-7",
      "sku": "",
      "color": "C-7",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-7.jpg"
    },
    "CD-C-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-8",
      "sku": "",
      "color": "C-8",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-8.jpg"
    },
    "CD-C-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CD-C-9",
      "sku": "",
      "color": "C-9",
      "style": "CD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CD/C-9.jpg"
    },
    "CJL-D-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-1",
      "sku": "",
      "color": "D-1",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-1.jpg"
    },
    "CJL-D-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-10",
      "sku": "",
      "color": "D-10",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-10.jpg"
    },
    "CJL-D-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-11",
      "sku": "",
      "color": "D-11",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-11.jpg"
    },
    "CJL-D-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-12",
      "sku": "",
      "color": "D-12",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-12.jpg"
    },
    "CJL-D-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-13",
      "sku": "",
      "color": "D-13",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-13.jpg"
    },
    "CJL-D-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-14",
      "sku": "",
      "color": "D-14",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-14.jpg"
    },
    "CJL-D-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-2",
      "sku": "",
      "color": "D-2",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-2.jpg"
    },
    "CJL-D-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-3",
      "sku": "",
      "color": "D-3",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-3.jpg"
    },
    "CJL-D-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-4",
      "sku": "",
      "color": "D-4",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-4.jpg"
    },
    "CJL-D-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-5",
      "sku": "",
      "color": "D-5",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-5.jpg"
    },
    "CJL-D-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-6",
      "sku": "",
      "color": "D-6",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-6.jpg"
    },
    "CJL-D-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-7",
      "sku": "",
      "color": "D-7",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-7.jpg"
    },
    "CJL-D-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-8",
      "sku": "",
      "color": "D-8",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-8.jpg"
    },
    "CJL-D-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "CJL-D-9",
      "sku": "",
      "color": "D-9",
      "style": "CJL",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/CJL/D-9.jpg"
    },
    "D-GEAR-U-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-1",
      "sku": "",
      "color": "U-1",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-1.jpg"
    },
    "D-GEAR-U-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-10",
      "sku": "",
      "color": "U-10",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-10.jpg"
    },
    "D-GEAR-U-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-2",
      "sku": "",
      "color": "U-2",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-2.jpg"
    },
    "D-GEAR-U-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-3",
      "sku": "",
      "color": "U-3",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-3.jpg"
    },
    "D-GEAR-U-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-4",
      "sku": "",
      "color": "U-4",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-4.png"
    },
    "D-GEAR-U-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-5",
      "sku": "",
      "color": "U-5",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-5.png"
    },
    "D-GEAR-U-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-6",
      "sku": "",
      "color": "U-6",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-6.jpg"
    },
    "D-GEAR-U-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-7",
      "sku": "",
      "color": "U-7",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-7.jpg"
    },
    "D-GEAR-U-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-8",
      "sku": "",
      "color": "U-8",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-8.jpg"
    },
    "D-GEAR-U-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-GEAR-U-9",
      "sku": "",
      "color": "U-9",
      "style": "D-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-GEAR/U-9.jpg"
    },
    "D-HEART-V-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-1",
      "sku": "",
      "color": "V-1",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-1.jpg"
    },
    "D-HEART-V-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-2",
      "sku": "",
      "color": "V-2",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-2.jpg"
    },
    "D-HEART-V-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-3",
      "sku": "",
      "color": "V-3",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-3.jpg"
    },
    "D-HEART-V-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-4",
      "sku": "",
      "color": "V-4",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-4.jpg"
    },
    "D-HEART-V-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-5",
      "sku": "",
      "color": "V-5",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-5.jpg"
    },
    "D-HEART-V-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-6",
      "sku": "",
      "color": "V-6",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-6.jpg"
    },
    "D-HEART-V-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-7",
      "sku": "",
      "color": "V-7",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-7.jpg"
    },
    "D-HEART-V-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-8",
      "sku": "",
      "color": "V-8",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-8.jpg"
    },
    "D-HEART-V-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-HEART-V-9",
      "sku": "",
      "color": "V-9",
      "style": "D-HEART",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-HEART/V-9.jpg"
    },
    "D-L-Y-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-1",
      "sku": "",
      "color": "Y-1",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-1.jpg"
    },
    "D-L-Y-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-10",
      "sku": "",
      "color": "Y-10",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-10.jpg"
    },
    "D-L-Y-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-11",
      "sku": "",
      "color": "Y-11",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-11.jpg"
    },
    "D-L-Y-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-2",
      "sku": "",
      "color": "Y-2",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-2.jpg"
    },
    "D-L-Y-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-3",
      "sku": "",
      "color": "Y-3",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-3.jpg"
    },
    "D-L-Y-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-4",
      "sku": "",
      "color": "Y-4",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-4.jpg"
    },
    "D-L-Y-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-5",
      "sku": "",
      "color": "Y-5",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-5.jpg"
    },
    "D-L-Y-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-6",
      "sku": "",
      "color": "Y-6",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-6.jpg"
    },
    "D-L-Y-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-7",
      "sku": "",
      "color": "Y-7",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-7.jpg"
    },
    "D-L-Y-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-8",
      "sku": "",
      "color": "Y-8",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-8.jpg"
    },
    "D-L-Y-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D-L-Y-9",
      "sku": "",
      "color": "Y-9",
      "style": "D-L",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D-L/Y-9.png"
    },
    "D100-9ed6f862b2d594dad3ba21038a48072": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-9ed6f862b2d594dad3ba21038a48072",
      "sku": "",
      "color": "9ed6f862b2d594dad3ba21038a48072",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/9ed6f862b2d594dad3ba21038a48072.jpg"
    },
    "D100-c7e8d3d0f94d00e88e92f7395eae7ee": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-c7e8d3d0f94d00e88e92f7395eae7ee",
      "sku": "",
      "color": "c7e8d3d0f94d00e88e92f7395eae7ee",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/c7e8d3d0f94d00e88e92f7395eae7ee.jpg"
    },
    "D100-c983c5959af6a50483a6bd91ce1dcb0": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-c983c5959af6a50483a6bd91ce1dcb0",
      "sku": "",
      "color": "c983c5959af6a50483a6bd91ce1dcb0",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/c983c5959af6a50483a6bd91ce1dcb0.jpg"
    },
    "D100-D100-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-D100-2",
      "sku": "",
      "color": "D100-2",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/D100-2.jpg"
    },
    "D100-D100-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-D100-5",
      "sku": "",
      "color": "D100-5",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/D100-5.jpg"
    },
    "D100-E-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-1",
      "sku": "",
      "color": "E-1",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-1.jpg"
    },
    "D100-E-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-10",
      "sku": "",
      "color": "E-10",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-10.jpg"
    },
    "D100-E-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-11",
      "sku": "",
      "color": "E-11",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-11.jpg"
    },
    "D100-E-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-12",
      "sku": "",
      "color": "E-12",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-12.jpg"
    },
    "D100-E-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-13",
      "sku": "",
      "color": "E-13",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-13.png"
    },
    "D100-E-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-14",
      "sku": "",
      "color": "E-14",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-14.png"
    },
    "D100-E-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-15",
      "sku": "",
      "color": "E-15",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-15.png"
    },
    "D100-E-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-2",
      "sku": "",
      "color": "E-2",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-2.jpg"
    },
    "D100-E-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-3",
      "sku": "",
      "color": "E-3",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-3.jpg"
    },
    "D100-E-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-4",
      "sku": "",
      "color": "E-4",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-4.jpg"
    },
    "D100-E-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-6",
      "sku": "",
      "color": "E-6",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-6.jpg"
    },
    "D100-E-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-7",
      "sku": "",
      "color": "E-7",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-7.png"
    },
    "D100-E-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-8",
      "sku": "",
      "color": "E-8",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-8.jpg"
    },
    "D100-E-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-E-9",
      "sku": "",
      "color": "E-9",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/E-9.jpg"
    },
    "D100-f8f2ed12afd978300d3957c766f6413": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-f8f2ed12afd978300d3957c766f6413",
      "sku": "",
      "color": "f8f2ed12afd978300d3957c766f6413",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/f8f2ed12afd978300d3957c766f6413.jpg"
    },
    "D100-微信图片_202208051001432": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "D100-微信图片_202208051001432",
      "sku": "",
      "color": "微信图片_202208051001432",
      "style": "D100",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/D100/微信图片_202208051001432.jpg"
    },
    "DFEB-H-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-H-1",
      "sku": "",
      "color": "H-1",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/H-1.JPG"
    },
    "DFEB-H-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-H-2",
      "sku": "",
      "color": "H-2",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/H-2.JPG"
    },
    "DFEB-H-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-H-3",
      "sku": "",
      "color": "H-3",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/H-3.JPG"
    },
    "DFEB-N-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-10",
      "sku": "",
      "color": "N-10",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-10.jpg"
    },
    "DFEB-N-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-4",
      "sku": "",
      "color": "N-4",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-4.jpg"
    },
    "DFEB-N-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-5",
      "sku": "",
      "color": "N-5",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-5.jpg"
    },
    "DFEB-N-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-6",
      "sku": "",
      "color": "N-6",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-6.jpg"
    },
    "DFEB-N-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-7",
      "sku": "",
      "color": "N-7",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-7.jpg"
    },
    "DFEB-N-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-8",
      "sku": "",
      "color": "N-8",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-8.jpg"
    },
    "DFEB-N-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DFEB-N-9",
      "sku": "",
      "color": "N-9",
      "style": "DFEB",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DFEB/N-9.jpg"
    },
    "DG-GEAR-W-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-1",
      "sku": "",
      "color": "W-1",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-1.jpg"
    },
    "DG-GEAR-W-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-10",
      "sku": "",
      "color": "W-10",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-10.jpg"
    },
    "DG-GEAR-W-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-11",
      "sku": "",
      "color": "W-11",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-11.jpg"
    },
    "DG-GEAR-W-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-12",
      "sku": "",
      "color": "W-12",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-12.jpg"
    },
    "DG-GEAR-W-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-13",
      "sku": "",
      "color": "W-13",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-13.jpg"
    },
    "DG-GEAR-W-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-14",
      "sku": "",
      "color": "W-14",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-14.jpg"
    },
    "DG-GEAR-W-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-15",
      "sku": "",
      "color": "W-15",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-15.jpg"
    },
    "DG-GEAR-W-16": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-16",
      "sku": "",
      "color": "W-16",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-16.jpg"
    },
    "DG-GEAR-W-17": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-17",
      "sku": "",
      "color": "W-17",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-17.jpg"
    },
    "DG-GEAR-W-18": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-18",
      "sku": "",
      "color": "W-18",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-18.jpg"
    },
    "DG-GEAR-W-19": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-19",
      "sku": "",
      "color": "W-19",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-19.jpg"
    },
    "DG-GEAR-W-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-2",
      "sku": "",
      "color": "W-2",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-2.jpg"
    },
    "DG-GEAR-W-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-3",
      "sku": "",
      "color": "W-3",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-3.jpg"
    },
    "DG-GEAR-W-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-4",
      "sku": "",
      "color": "W-4",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-4.jpg"
    },
    "DG-GEAR-W-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-5",
      "sku": "",
      "color": "W-5",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-5.jpg"
    },
    "DG-GEAR-W-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-6",
      "sku": "",
      "color": "W-6",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-6.png"
    },
    "DG-GEAR-W-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-7",
      "sku": "",
      "color": "W-7",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-7.jpg"
    },
    "DG-GEAR-W-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-8",
      "sku": "",
      "color": "W-8",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-8.jpg"
    },
    "DG-GEAR-W-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DG-GEAR-W-9",
      "sku": "",
      "color": "W-9",
      "style": "DG-GEAR",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DG-GEAR/W-9.jpg"
    },
    "DGM-DSK-EB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-1",
      "sku": "",
      "color": "EB-1",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-1.jpg"
    },
    "DGM-DSK-EB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-2",
      "sku": "",
      "color": "EB-2",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-2.jpg"
    },
    "DGM-DSK-EB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-3",
      "sku": "",
      "color": "EB-3",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-3.jpg"
    },
    "DGM-DSK-EB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-4",
      "sku": "",
      "color": "EB-4",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-4.jpg"
    },
    "DGM-DSK-EB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-5",
      "sku": "",
      "color": "EB-5",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-5.jpg"
    },
    "DGM-DSK-EB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-6",
      "sku": "",
      "color": "EB-6",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-6.jpg"
    },
    "DGM-DSK-EB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-7",
      "sku": "",
      "color": "EB-7",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-7.jpg"
    },
    "DGM-DSK-EB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-EB-8",
      "sku": "",
      "color": "EB-8",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/EB-8.jpg"
    },
    "DGM-DSK-FB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-1",
      "sku": "",
      "color": "FB-1",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-1.png"
    },
    "DGM-DSK-FB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-2",
      "sku": "",
      "color": "FB-2",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-2.jpg"
    },
    "DGM-DSK-FB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-3",
      "sku": "",
      "color": "FB-3",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-3.jpg"
    },
    "DGM-DSK-FB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-4",
      "sku": "",
      "color": "FB-4",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-4.jpg"
    },
    "DGM-DSK-FB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-5",
      "sku": "",
      "color": "FB-5",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-5.png"
    },
    "DGM-DSK-FB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-6",
      "sku": "",
      "color": "FB-6",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-6.png"
    },
    "DGM-DSK-FB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-7",
      "sku": "",
      "color": "FB-7",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-7.jpg"
    },
    "DGM-DSK-FB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-8",
      "sku": "",
      "color": "FB-8",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-8.jpg"
    },
    "DGM-DSK-FB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DGM-DSK-FB-9",
      "sku": "",
      "color": "FB-9",
      "style": "DGM-DSK",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DGM-DSK/FB-9.jpg"
    },
    "DJAN-M-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-1",
      "sku": "",
      "color": "M-1",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-1.jpg"
    },
    "DJAN-M-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-10",
      "sku": "",
      "color": "M-10",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-10.jpg"
    },
    "DJAN-M-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-2",
      "sku": "",
      "color": "M-2",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-2.jpg"
    },
    "DJAN-M-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-3",
      "sku": "",
      "color": "M-3",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-3.jpg"
    },
    "DJAN-M-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-4",
      "sku": "",
      "color": "M-4",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-4.jpg"
    },
    "DJAN-M-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-5",
      "sku": "",
      "color": "M-5",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-5.jpg"
    },
    "DJAN-M-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-6",
      "sku": "",
      "color": "M-6",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-6.jpg"
    },
    "DJAN-M-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-7",
      "sku": "",
      "color": "M-7",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-7.png"
    },
    "DJAN-M-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-8",
      "sku": "",
      "color": "M-8",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-8.jpg"
    },
    "DJAN-M-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DJAN-M-9",
      "sku": "",
      "color": "M-9",
      "style": "DJAN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DJAN/M-9.jpg"
    },
    "DRAGON EYE-IB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-1",
      "sku": "",
      "color": "IB-1",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-1.jpg"
    },
    "DRAGON EYE-IB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-2",
      "sku": "",
      "color": "IB-2",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-2.jpg"
    },
    "DRAGON EYE-IB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-3",
      "sku": "",
      "color": "IB-3",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-3.jpg"
    },
    "DRAGON EYE-IB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-4",
      "sku": "",
      "color": "IB-4",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-4.jpg"
    },
    "DRAGON EYE-IB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-5",
      "sku": "",
      "color": "IB-5",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-5.jpg"
    },
    "DRAGON EYE-IB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-6",
      "sku": "",
      "color": "IB-6",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-6.jpg"
    },
    "DRAGON EYE-IB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-7",
      "sku": "",
      "color": "IB-7",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-7.jpg"
    },
    "DRAGON EYE-IB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-8",
      "sku": "",
      "color": "IB-8",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-8.jpg"
    },
    "DRAGON EYE-IB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON EYE-IB-9",
      "sku": "",
      "color": "IB-9",
      "style": "DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON EYE/IB-9.jpg"
    },
    "DRAGON-SKIN-R-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-1",
      "sku": "",
      "color": "R-1",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-1.jpg"
    },
    "DRAGON-SKIN-R-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-10",
      "sku": "",
      "color": "R-10",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-10.jpg"
    },
    "DRAGON-SKIN-R-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-11",
      "sku": "",
      "color": "R-11",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-11.jpg"
    },
    "DRAGON-SKIN-R-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-2",
      "sku": "",
      "color": "R-2",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-2.jpg"
    },
    "DRAGON-SKIN-R-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-3",
      "sku": "",
      "color": "R-3",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-3.jpg"
    },
    "DRAGON-SKIN-R-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-4",
      "sku": "",
      "color": "R-4",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-4.jpg"
    },
    "DRAGON-SKIN-R-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-5",
      "sku": "",
      "color": "R-5",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-5.jpg"
    },
    "DRAGON-SKIN-R-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-6",
      "sku": "",
      "color": "R-6",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-6.jpg"
    },
    "DRAGON-SKIN-R-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-7",
      "sku": "",
      "color": "R-7",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-7.jpg"
    },
    "DRAGON-SKIN-R-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-8",
      "sku": "",
      "color": "R-8",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-8.jpg"
    },
    "DRAGON-SKIN-R-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "DRAGON-SKIN-R-9",
      "sku": "",
      "color": "R-9",
      "style": "DRAGON-SKIN",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/DRAGON-SKIN/R-9.jpg"
    },
    "FLW-Z-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-1",
      "sku": "",
      "color": "Z-1",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-1.jpg"
    },
    "FLW-Z-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-10",
      "sku": "",
      "color": "Z-10",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-10.jpg"
    },
    "FLW-Z-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-11",
      "sku": "",
      "color": "Z-11",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-11.jpg"
    },
    "FLW-Z-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-12",
      "sku": "",
      "color": "Z-12",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-12.jpg"
    },
    "FLW-Z-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-13",
      "sku": "",
      "color": "Z-13",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-13.jpg"
    },
    "FLW-Z-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-14",
      "sku": "",
      "color": "Z-14",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-14.jpg"
    },
    "FLW-Z-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-2",
      "sku": "",
      "color": "Z-2",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-2.jpg"
    },
    "FLW-Z-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-3",
      "sku": "",
      "color": "Z-3",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-3.jpg"
    },
    "FLW-Z-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-4",
      "sku": "",
      "color": "Z-4",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-4.jpg"
    },
    "FLW-Z-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-5",
      "sku": "",
      "color": "Z-5",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-5.jpg"
    },
    "FLW-Z-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-6",
      "sku": "",
      "color": "Z-6",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-6.jpg"
    },
    "FLW-Z-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-7",
      "sku": "",
      "color": "Z-7",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-7.jpg"
    },
    "FLW-Z-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-8",
      "sku": "",
      "color": "Z-8",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-8.jpg"
    },
    "FLW-Z-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "FLW-Z-9",
      "sku": "",
      "color": "Z-9",
      "style": "FLW",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/FLW/Z-9.jpg"
    },
    "HBDG-B-18": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-B-18",
      "sku": "",
      "color": "B-18",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/B-18.jpg"
    },
    "HBDG-G-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-10",
      "sku": "",
      "color": "G-10",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-10.jpg"
    },
    "HBDG-G-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-11",
      "sku": "",
      "color": "G-11",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-11.jpg"
    },
    "HBDG-G-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-12",
      "sku": "",
      "color": "G-12",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-12.jpg"
    },
    "HBDG-G-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-13",
      "sku": "",
      "color": "G-13",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-13.jpg"
    },
    "HBDG-G-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-14",
      "sku": "",
      "color": "G-14",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-14.jpg"
    },
    "HBDG-G-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-15",
      "sku": "",
      "color": "G-15",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-15.jpg"
    },
    "HBDG-G-16": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-16",
      "sku": "",
      "color": "G-16",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-16.jpg"
    },
    "HBDG-G-17": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-17",
      "sku": "",
      "color": "G-17",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-17.jpg"
    },
    "HBDG-G-18": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-18",
      "sku": "",
      "color": "G-18",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-18.jpg"
    },
    "HBDG-G-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-2",
      "sku": "",
      "color": "G-2",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-2.jpg"
    },
    "HBDG-G-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-3",
      "sku": "",
      "color": "G-3",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-3.jpg"
    },
    "HBDG-G-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-4",
      "sku": "",
      "color": "G-4",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-4.jpg"
    },
    "HBDG-G-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-5",
      "sku": "",
      "color": "G-5",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-5.jpg"
    },
    "HBDG-G-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-6",
      "sku": "",
      "color": "G-6",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-6.jpg"
    },
    "HBDG-G-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-7",
      "sku": "",
      "color": "G-7",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-7.jpg"
    },
    "HBDG-G-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-8",
      "sku": "",
      "color": "G-8",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-8.jpg"
    },
    "HBDG-G-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HBDG-G-9",
      "sku": "",
      "color": "G-9",
      "style": "HBDG",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HBDG/G-9.jpg"
    },
    "HOD-AB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-1",
      "sku": "",
      "color": "AB-1",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-1.png"
    },
    "HOD-AB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-2",
      "sku": "",
      "color": "AB-2",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-2.png"
    },
    "HOD-AB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-3",
      "sku": "",
      "color": "AB-3",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-3.png"
    },
    "HOD-AB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-4",
      "sku": "",
      "color": "AB-4",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-4.png"
    },
    "HOD-AB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-5",
      "sku": "",
      "color": "AB-5",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-5.png"
    },
    "HOD-AB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-6",
      "sku": "",
      "color": "AB-6",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-6.png"
    },
    "HOD-AB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-7",
      "sku": "",
      "color": "AB-7",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-7.png"
    },
    "HOD-AB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-AB-8",
      "sku": "",
      "color": "AB-8",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/AB-8.png"
    },
    "HOD-BB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-1",
      "sku": "",
      "color": "BB-1",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-1.png"
    },
    "HOD-BB-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-10",
      "sku": "",
      "color": "BB-10",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-10.png"
    },
    "HOD-BB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-2",
      "sku": "",
      "color": "BB-2",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-2.png"
    },
    "HOD-BB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-3",
      "sku": "",
      "color": "BB-3",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-3.png"
    },
    "HOD-BB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-4",
      "sku": "",
      "color": "BB-4",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-4.png"
    },
    "HOD-BB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-5",
      "sku": "",
      "color": "BB-5",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-5.png"
    },
    "HOD-BB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-6",
      "sku": "",
      "color": "BB-6",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-6.png"
    },
    "HOD-BB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-7",
      "sku": "",
      "color": "BB-7",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-7.png"
    },
    "HOD-BB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-8",
      "sku": "",
      "color": "BB-8",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-8.png"
    },
    "HOD-BB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HOD-BB-9",
      "sku": "",
      "color": "BB-9",
      "style": "HOD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HOD/BB-9.png"
    },
    "HXHS-MB--2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-MB--2",
      "sku": "",
      "color": "MB--2",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/MB--2.jpg"
    },
    "HXHS-MB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-MB-1",
      "sku": "",
      "color": "MB-1",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/MB-1.jpg"
    },
    "HXHS-MB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-MB-3",
      "sku": "",
      "color": "MB-3",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/MB-3.jpg"
    },
    "HXHS-MB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-MB-4",
      "sku": "",
      "color": "MB-4",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/MB-4.jpg"
    },
    "HXHS-MB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-MB-5",
      "sku": "",
      "color": "MB-5",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/MB-5.jpg"
    },
    "HXHS-NB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-1",
      "sku": "",
      "color": "NB-1",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-1.jpg"
    },
    "HXHS-NB-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-10",
      "sku": "",
      "color": "NB-10",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-10.jpg"
    },
    "HXHS-NB-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-11",
      "sku": "",
      "color": "NB-11",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-11.jpg"
    },
    "HXHS-NB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-2",
      "sku": "",
      "color": "NB-2",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-2.jpg"
    },
    "HXHS-NB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-3",
      "sku": "",
      "color": "NB-3",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-3.jpg"
    },
    "HXHS-NB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-4",
      "sku": "",
      "color": "NB-4",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-4.jpg"
    },
    "HXHS-NB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-5",
      "sku": "",
      "color": "NB-5",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-5.jpg"
    },
    "HXHS-NB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-6",
      "sku": "",
      "color": "NB-6",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-6.jpg"
    },
    "HXHS-NB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-7",
      "sku": "",
      "color": "NB-7",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-7.jpg"
    },
    "HXHS-NB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-8",
      "sku": "",
      "color": "NB-8",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-8.jpg"
    },
    "HXHS-NB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "HXHS-NB-9",
      "sku": "",
      "color": "NB-9",
      "style": "HXHS",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/HXHS/NB-9.jpg"
    },
    "JUNED-GB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-1",
      "sku": "",
      "color": "GB-1",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-1.jpg"
    },
    "JUNED-GB-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-10",
      "sku": "",
      "color": "GB-10",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-10.jpg"
    },
    "JUNED-GB-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-11",
      "sku": "",
      "color": "GB-11",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-11.jpg"
    },
    "JUNED-GB-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-12",
      "sku": "",
      "color": "GB-12",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-12.jpg"
    },
    "JUNED-GB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-2",
      "sku": "",
      "color": "GB-2",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-2.jpg"
    },
    "JUNED-GB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-3",
      "sku": "",
      "color": "GB-3",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-3.jpg"
    },
    "JUNED-GB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-4",
      "sku": "",
      "color": "GB-4",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-4.jpg"
    },
    "JUNED-GB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-5",
      "sku": "",
      "color": "GB-5",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-5.jpg"
    },
    "JUNED-GB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-6",
      "sku": "",
      "color": "GB-6",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-6.jpg"
    },
    "JUNED-GB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-7",
      "sku": "",
      "color": "GB-7",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-7.jpg"
    },
    "JUNED-GB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-8",
      "sku": "",
      "color": "GB-8",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-8.jpg"
    },
    "JUNED-GB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "JUNED-GB-9",
      "sku": "",
      "color": "GB-9",
      "style": "JUNED",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/JUNED/GB-9.jpg"
    },
    "MD-K-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-1",
      "sku": "",
      "color": "K-1",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-1.jpg"
    },
    "MD-K-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-2",
      "sku": "",
      "color": "K-2",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-2.jpg"
    },
    "MD-K-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-3",
      "sku": "",
      "color": "K-3",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-3.jpg"
    },
    "MD-K-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-4",
      "sku": "",
      "color": "K-4",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-4.jpg"
    },
    "MD-K-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-5",
      "sku": "",
      "color": "K-5",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-5.jpg"
    },
    "MD-K-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-6",
      "sku": "",
      "color": "K-6",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-6.jpg"
    },
    "MD-K-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-7",
      "sku": "",
      "color": "K-7",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-7.png"
    },
    "MD-K-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-8",
      "sku": "",
      "color": "K-8",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-8.jpg"
    },
    "MD-K-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MD-K-9",
      "sku": "",
      "color": "K-9",
      "style": "MD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MD/K-9.jpg"
    },
    "MDE-I-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-1",
      "sku": "",
      "color": "I-1",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-1.png"
    },
    "MDE-I-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-10",
      "sku": "",
      "color": "I-10",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-10.jpg"
    },
    "MDE-I-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-2",
      "sku": "",
      "color": "I-2",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-2.jpg"
    },
    "MDE-I-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-3",
      "sku": "",
      "color": "I-3",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-3.jpg"
    },
    "MDE-I-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-4",
      "sku": "",
      "color": "I-4",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-4.jpg"
    },
    "MDE-I-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-5",
      "sku": "",
      "color": "I-5",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-5.jpg"
    },
    "MDE-I-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-6",
      "sku": "",
      "color": "I-6",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-6.jpg"
    },
    "MDE-I-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-7",
      "sku": "",
      "color": "I-7",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-7.jpg"
    },
    "MDE-I-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-8",
      "sku": "",
      "color": "I-8",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-8.jpg"
    },
    "MDE-I-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-I-9",
      "sku": "",
      "color": "I-9",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/I-9.jpg"
    },
    "MDE-J-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-J-1",
      "sku": "",
      "color": "J-1",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/J-1.jpg"
    },
    "MDE-J-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-J-2",
      "sku": "",
      "color": "J-2",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/J-2.jpg"
    },
    "MDE-J-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MDE-J-3",
      "sku": "",
      "color": "J-3",
      "style": "MDE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MDE/J-3.jpg"
    },
    "MMC-L-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-1",
      "sku": "",
      "color": "L-1",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-1.JPG"
    },
    "MMC-L-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-10",
      "sku": "",
      "color": "L-10",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-10.JPG"
    },
    "MMC-L-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-11",
      "sku": "",
      "color": "L-11",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-11.JPG"
    },
    "MMC-L-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-12",
      "sku": "",
      "color": "L-12",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-12.JPG"
    },
    "MMC-L-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-13",
      "sku": "",
      "color": "L-13",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-13.JPG"
    },
    "MMC-L-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-14",
      "sku": "",
      "color": "L-14",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-14.JPG"
    },
    "MMC-L-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-15",
      "sku": "",
      "color": "L-15",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-15.JPG"
    },
    "MMC-L-16": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-16",
      "sku": "",
      "color": "L-16",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-16.JPG"
    },
    "MMC-L-17": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-17",
      "sku": "",
      "color": "L-17",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-17.JPG"
    },
    "MMC-L-18": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-18",
      "sku": "",
      "color": "L-18",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-18.JPG"
    },
    "MMC-L-19": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-19",
      "sku": "",
      "color": "L-19",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-19.JPG"
    },
    "MMC-L-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-2",
      "sku": "",
      "color": "L-2",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-2.JPG"
    },
    "MMC-L-20": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-20",
      "sku": "",
      "color": "L-20",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-20.JPG"
    },
    "MMC-L-21": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-21",
      "sku": "",
      "color": "L-21",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-21.JPG"
    },
    "MMC-L-22": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-22",
      "sku": "",
      "color": "L-22",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-22.JPG"
    },
    "MMC-L-23": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-23",
      "sku": "",
      "color": "L-23",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-23.JPG"
    },
    "MMC-L-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-3",
      "sku": "",
      "color": "L-3",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-3.JPG"
    },
    "MMC-L-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-4",
      "sku": "",
      "color": "L-4",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-4.JPG"
    },
    "MMC-L-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-5",
      "sku": "",
      "color": "L-5",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-5.JPG"
    },
    "MMC-L-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-6",
      "sku": "",
      "color": "L-6",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-6.JPG"
    },
    "MMC-L-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-7",
      "sku": "",
      "color": "L-7",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-7.JPG"
    },
    "MMC-L-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-8",
      "sku": "",
      "color": "L-8",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-8.JPG"
    },
    "MMC-L-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMC-L-9",
      "sku": "",
      "color": "L-9",
      "style": "MMC",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMC/L-9.JPG"
    },
    "MMD-MMD-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-1",
      "sku": "",
      "color": "MMD-1",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-1.JPG"
    },
    "MMD-MMD-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-2",
      "sku": "",
      "color": "MMD-2",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-2.JPG"
    },
    "MMD-MMD-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-3",
      "sku": "",
      "color": "MMD-3",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-3.JPG"
    },
    "MMD-MMD-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-4",
      "sku": "",
      "color": "MMD-4",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-4.JPG"
    },
    "MMD-MMD-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-5",
      "sku": "",
      "color": "MMD-5",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-5.JPG"
    },
    "MMD-MMD-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-6",
      "sku": "",
      "color": "MMD-6",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-6.JPG"
    },
    "MMD-MMD-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-7",
      "sku": "",
      "color": "MMD-7",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-7.JPG"
    },
    "MMD-MMD-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-8",
      "sku": "",
      "color": "MMD-8",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-8.JPG"
    },
    "MMD-MMD-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "MMD-MMD-9",
      "sku": "",
      "color": "MMD-9",
      "style": "MMD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/MMD/MMD-9.JPG"
    },
    "NDO-0-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-0-10",
      "sku": "",
      "color": "0-10",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/0-10.jpg"
    },
    "NDO-0-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-0-14",
      "sku": "",
      "color": "0-14",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/0-14.jpg"
    },
    "NDO-0-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-0-4",
      "sku": "",
      "color": "0-4",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/0-4.jpg"
    },
    "NDO-O-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-1",
      "sku": "",
      "color": "O-1",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-1.jpg"
    },
    "NDO-O-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-10",
      "sku": "",
      "color": "O-10",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-10.jpg"
    },
    "NDO-O-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-11",
      "sku": "",
      "color": "O-11",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-11.jpg"
    },
    "NDO-O-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-12",
      "sku": "",
      "color": "O-12",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-12.jpg"
    },
    "NDO-O-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-13",
      "sku": "",
      "color": "O-13",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-13.jpg"
    },
    "NDO-O-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-14",
      "sku": "",
      "color": "O-14",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-14.jpg"
    },
    "NDO-O-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-15",
      "sku": "",
      "color": "O-15",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-15.jpg"
    },
    "NDO-O-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-2",
      "sku": "",
      "color": "O-2",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-2.jpg"
    },
    "NDO-O-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-3",
      "sku": "",
      "color": "O-3",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-3.jpg"
    },
    "NDO-O-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-5",
      "sku": "",
      "color": "O-5",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-5.jpg"
    },
    "NDO-O-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-6",
      "sku": "",
      "color": "O-6",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-6.jpg"
    },
    "NDO-O-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-7",
      "sku": "",
      "color": "O-7",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-7.jpg"
    },
    "NDO-O-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-8",
      "sku": "",
      "color": "O-8",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-8.jpg"
    },
    "NDO-O-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDO-O-9",
      "sku": "",
      "color": "O-9",
      "style": "NDO",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDO/O-9.jpg"
    },
    "NDQ-P-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-1",
      "sku": "",
      "color": "P-1",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-1.jpg"
    },
    "NDQ-P-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-10",
      "sku": "",
      "color": "P-10",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-10.jpg"
    },
    "NDQ-P-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-11",
      "sku": "",
      "color": "P-11",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-11.jpg"
    },
    "NDQ-P-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-12",
      "sku": "",
      "color": "P-12",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-12.jpg"
    },
    "NDQ-P-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-13",
      "sku": "",
      "color": "P-13",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-13.jpg"
    },
    "NDQ-P-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-14",
      "sku": "",
      "color": "P-14",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-14.jpg"
    },
    "NDQ-P-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-15",
      "sku": "",
      "color": "P-15",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-15.jpg"
    },
    "NDQ-P-16": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-16",
      "sku": "",
      "color": "P-16",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-16.jpg"
    },
    "NDQ-P-17": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-17",
      "sku": "",
      "color": "P-17",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-17.jpg"
    },
    "NDQ-P-18": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-18",
      "sku": "",
      "color": "P-18",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-18.jpg"
    },
    "NDQ-P-19": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-19",
      "sku": "",
      "color": "P-19",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-19.jpg"
    },
    "NDQ-P-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-2",
      "sku": "",
      "color": "P-2",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-2.jpg"
    },
    "NDQ-P-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-3",
      "sku": "",
      "color": "P-3",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-3.jpg"
    },
    "NDQ-P-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-4",
      "sku": "",
      "color": "P-4",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-4.jpg"
    },
    "NDQ-P-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-5",
      "sku": "",
      "color": "P-5",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-5.jpg"
    },
    "NDQ-P-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-6",
      "sku": "",
      "color": "P-6",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-6.jpg"
    },
    "NDQ-P-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-7",
      "sku": "",
      "color": "P-7",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-7.jpg"
    },
    "NDQ-P-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-8",
      "sku": "",
      "color": "P-8",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-8.jpg"
    },
    "NDQ-P-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "NDQ-P-9",
      "sku": "",
      "color": "P-9",
      "style": "NDQ",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/NDQ/P-9.jpg"
    },
    "R-S-regular skull  (3)": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-regular skull  (3)",
      "sku": "",
      "color": "regular skull  (3)",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R/S-regular skull  (3).jpg"
    },
    "R-S-X-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-X-1",
      "sku": "",
      "color": "X-1",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R-S/X-1.jpg"
    },
    "R-S-X-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-X-2",
      "sku": "",
      "color": "X-2",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R-S/X-2.jpg"
    },
    "R-S-X-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-X-3",
      "sku": "",
      "color": "X-3",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R-S/X-3.jpg"
    },
    "R-S-X-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-X-4",
      "sku": "",
      "color": "X-4",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R-S/X-4.jpg"
    },
    "R-S-X-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-X-5",
      "sku": "",
      "color": "X-5",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R-S/X-5.jpg"
    },
    "R-S-X-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "R-S-X-6",
      "sku": "",
      "color": "X-6",
      "style": "R-S",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/R-S/X-6.JPG"
    },
    "RD-HB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-1",
      "sku": "",
      "color": "HB-1",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-1.jpg"
    },
    "RD-HB-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-10",
      "sku": "",
      "color": "HB-10",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-10.jpg"
    },
    "RD-HB-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-11",
      "sku": "",
      "color": "HB-11",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-11.jpg"
    },
    "RD-HB-12": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-12",
      "sku": "",
      "color": "HB-12",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-12.png"
    },
    "RD-HB-13": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-13",
      "sku": "",
      "color": "HB-13",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-13.png"
    },
    "RD-HB-14": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-14",
      "sku": "",
      "color": "HB-14",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-14.jpg"
    },
    "RD-HB-15": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-15",
      "sku": "",
      "color": "HB-15",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-15.jpg"
    },
    "RD-HB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-2",
      "sku": "",
      "color": "HB-2",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-2.png"
    },
    "RD-HB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-3",
      "sku": "",
      "color": "HB-3",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-3.jpg"
    },
    "RD-HB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-4",
      "sku": "",
      "color": "HB-4",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-4.png"
    },
    "RD-HB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-5",
      "sku": "",
      "color": "HB-5",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-5.jpg"
    },
    "RD-HB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-6",
      "sku": "",
      "color": "HB-6",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-6.png"
    },
    "RD-HB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-7",
      "sku": "",
      "color": "HB-7",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-7.png"
    },
    "RD-HB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-8",
      "sku": "",
      "color": "HB-8",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-8.png"
    },
    "RD-HB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "RD-HB-9",
      "sku": "",
      "color": "HB-9",
      "style": "RD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/RD/HB-9.png"
    },
    "skull-solid-Q-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-1",
      "sku": "",
      "color": "Q-1",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-1.jpg"
    },
    "skull-solid-Q-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-2",
      "sku": "",
      "color": "Q-2",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-2.jpg"
    },
    "skull-solid-Q-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-3",
      "sku": "",
      "color": "Q-3",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-3.jpg"
    },
    "skull-solid-Q-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-4",
      "sku": "",
      "color": "Q-4",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-4.jpg"
    },
    "skull-solid-Q-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-6",
      "sku": "",
      "color": "Q-6",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-6.jpg"
    },
    "skull-solid-Q-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-7",
      "sku": "",
      "color": "Q-7",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-7.jpg"
    },
    "skull-solid-Q-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-8",
      "sku": "",
      "color": "Q-8",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-8.jpg"
    },
    "skull-solid-Q-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "skull-solid-Q-9",
      "sku": "",
      "color": "Q-9",
      "style": "skull-solid",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/skull-solid/Q-9.jpg"
    },
    "THW DRAGON EYE-LB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-1",
      "sku": "",
      "color": "LB-1",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-1.jpg"
    },
    "THW DRAGON EYE-LB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-2",
      "sku": "",
      "color": "LB-2",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-2.jpg"
    },
    "THW DRAGON EYE-LB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-3",
      "sku": "",
      "color": "LB-3",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-3.jpg"
    },
    "THW DRAGON EYE-LB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-4",
      "sku": "",
      "color": "LB-4",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-4.jpg"
    },
    "THW DRAGON EYE-LB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-5",
      "sku": "",
      "color": "LB-5",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-5.jpg"
    },
    "THW DRAGON EYE-LB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-6",
      "sku": "",
      "color": "LB-6",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-6.jpg"
    },
    "THW DRAGON EYE-LB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-7",
      "sku": "",
      "color": "LB-7",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-7.jpg"
    },
    "THW DRAGON EYE-LB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-8",
      "sku": "",
      "color": "LB-8",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-8.jpg"
    },
    "THW DRAGON EYE-LB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "THW DRAGON EYE-LB-9",
      "sku": "",
      "color": "LB-9",
      "style": "THW DRAGON EYE",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/THW DRAGON EYE/LB-9.jpg"
    },
    "WD-CB-1": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-1",
      "sku": "",
      "color": "CB-1",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-1.jpg"
    },
    "WD-CB-10": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-10",
      "sku": "",
      "color": "CB-10",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-10.jpg"
    },
    "WD-CB-11": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-11",
      "sku": "",
      "color": "CB-11",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-11.jpg"
    },
    "WD-CB-2": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-2",
      "sku": "",
      "color": "CB-2",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-2.jpg"
    },
    "WD-CB-3": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-3",
      "sku": "",
      "color": "CB-3",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-3.jpg"
    },
    "WD-CB-4": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-4",
      "sku": "",
      "color": "CB-4",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-4.jpg"
    },
    "WD-CB-5": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-5",
      "sku": "",
      "color": "CB-5",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-5.jpg"
    },
    "WD-CB-6": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-6",
      "sku": "",
      "color": "CB-6",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-6.jpg"
    },
    "WD-CB-7": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-7",
      "sku": "",
      "color": "CB-7",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-7.jpg"
    },
    "WD-CB-8": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-8",
      "sku": "",
      "color": "CB-8",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-8.jpg"
    },
    "WD-CB-9": {
      "price": 0,
      "quantity": 1,
      "type": "dice",
      "serial": "WD-CB-9",
      "sku": "",
      "color": "CB-9",
      "style": "WD",
      "other": [
        "set"
      ],
      "setQuantity": 1,
      "imageResource": "dice/assortedImages/WD/CB-9.jpg"
    }
  }