import React, { useEffect, useState } from 'react';
import { DiceProduct } from '../../../../../../../types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/sharp-regular-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/sharp-regular-svg-icons/faChevronLeft';
import { faChevronDown } from '@fortawesome/sharp-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/sharp-regular-svg-icons/faChevronUp';
import CarouselItem from './CarouselItem';
import { motion, useMotionValue } from 'framer-motion';
import { setDicePreview } from '../../../../../../store/preview';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-duotone-svg-icons/faUpRightAndDownLeftFromCenter';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getColor, getSelection, getStyle, setSelection, setStyle } from '../../../../../../store/diceFilters';
import { getStyles } from '../../../../../../store/meta';
import ExtraInfo from './ExtraInfo';

interface ViewCarouselProps {
    filtered: DiceProduct[]
}

const DRAG_BUFFER = 25;

const ViewCarousel: React.FC<ViewCarouselProps> = ({ filtered }) => {
    const dispatch = useDispatch();
    const serial = useSelector(getSelection);
    const style = useSelector(getStyle);
    const color = useSelector(getColor);
    const styles = useSelector(getStyles);
    // const colors = useSelector(getColors);

    // Cursors
    const [cursor, setCursor] = useState<number>(0); // color
    const [styleCursor, setStyleCursor] = useState<number>(0); // style

    // Horizontal
    const incrementCursor = () => {
        if (filtered.length <= 0) return;
        let next = (cursor + 1) % filtered.length;
        setCursor(next);
        dispatch(setSelection(filtered[next].serial))
    }
    const decrementCursor = () => {
        if (filtered.length <= 0) return;
        let prev = cursor - 1;
        if (prev < 0) {
            prev = filtered.length - 1;
            dispatch(setSelection(filtered[prev].serial))
            return;
        }
        prev = prev % filtered.length;
        setCursor(prev)
        dispatch(setSelection(filtered[prev].serial))
    }

    // Vertical
    const incrementStyleCursor = () => {
        if (styles.length <= 0) return;
        let next = (styleCursor + 1) % styles.length;
        setStyleCursor(next);
        dispatch(setStyle(styles[next]));
    }
    const decrementStyleCursor = () => {
        if (styles.length <= 0) return;
        let prev = styleCursor - 1;
        if (prev < 0) {
            prev = styles.length - 1;
            setStyleCursor(prev);
            dispatch(setStyle(styles[prev]))
            return;
        }
        prev = prev % styles.length;
        setStyleCursor(prev);
        dispatch(setStyle(styles[prev]))
    }

    // Dragging
    const dragX = useMotionValue(0);
    const handleonDragEnd = () => {
        const x = dragX.get();
        if (x <= -DRAG_BUFFER) {
            incrementCursor()
        } else if (x >= DRAG_BUFFER) {
            decrementCursor();
        }
    }

    useEffect(() => setCursor(0), [style])

    useEffect(() => {
        setCursor(0);
        if (filtered[0]) setSelection(filtered[0].serial)
    }, [styleCursor])

    useEffect(() => {
        if (!color && !serial) return;
        if (color) {
            for (let n: number = 0; n < filtered.length; n++) {
                if (color === filtered[n].color) return setCursor(n)
            }
        } else {
            for (let n: number = 0; n < filtered.length; n++) {
                if (serial === filtered[n].serial) return setCursor(n)
            }
        }
    }, [color])

    return (
        <>
            <div className='relative flex flex-col justify-center grow py-2 '>
                {/* Children */}
                <motion.div
                    className='flex items-center w-full size-full cursor-grab active:cursor-grabbing'
                    drag="x"
                    dragConstraints={{
                        left: 0,
                        right: 0
                    }}
                    onDragEnd={handleonDragEnd}
                    animate={{
                        translateX: `-${cursor * 100}%`
                    }}
                    style={{
                        x: dragX
                    }}

                >
                    {filtered.map((p, i) => <CarouselItem onSelect={() => setCursor(i)} key={i} product={p} spotlight={cursor === i} />)}
                </motion.div>

                {/* Controls */}
                {/* Up Arrow */}
                <div className='absolute w-full inset-x-0 top-0 flex justify-center'>
                    <div className='w-12 px-3 py-1 flex justify-center items-center hover: cursor-pointer bg-red-950 bg-opacity-60'
                        onClick={decrementStyleCursor}
                    >
                        <motion.div
                            layout
                            whileTap={{ translateY: -8, transition: { duration: .3 } }}
                        >
                            <FontAwesomeIcon className='text-3xl text-white' icon={faChevronUp} />
                        </motion.div>
                    </div>
                </div>
                {/* Down */}
                <div className='absolute w-full inset-x-0 bottom-0 flex justify-center'>
                    <div className='w-12 px-3 pt-1 flex justify-center items-center hover: cursor-pointer bg-red-950 bg-opacity-60'
                        onClick={incrementStyleCursor}
                    >
                        <motion.div
                            layout
                            whileTap={{ translateY: 8, transition: { duration: .3 } }}
                        >
                            <FontAwesomeIcon className='text-3xl text-white' icon={faChevronDown} />
                        </motion.div>
                    </div>
                </div>

                {/* Left */}
                <div className='absolute left-0 h-12 px-2 pt-1 flex justify-center items-center hover:cursor-pointer bg-red-950 bg-opacity-60'
                    onClick={decrementCursor}
                >
                    <motion.div
                        layout
                        whileTap={{ translateX: -8, transition: { duration: .3 } }}
                    >
                        <FontAwesomeIcon className='text-3xl text-white' icon={faChevronLeft} />
                    </motion.div>
                </div>
                {/* Right */}
                <div className='absolute right-0 h-12 px-2 pt-1 flex justify-center items-center hover:cursor-pointer bg-red-950 bg-opacity-60'
                    onClick={incrementCursor}
                >
                    <motion.div
                        layout
                        whileTap={{ translateX: 8, transition: { duration: .3 } }}
                    >
                        <FontAwesomeIcon className='text-3xl text-white' icon={faChevronRight} />
                    </motion.div>
                </div>
                {/* Expand */}
                <motion.div
                    className='absolute bottom-0 left-0 ml-[15%] mb-4 rounded bg-red-950 opacity-80 flex items-center justify-center p-1  hover:cursor-pointer'
                    onClick={() => dispatch(setDicePreview(filtered[cursor].serial))}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: .8, transition: { duration: .35 } }}
                >
                    <FontAwesomeIcon className='text-zinc-200 text-3xl -rotate-90' icon={faUpRightAndDownLeftFromCenter} />
                </motion.div>
            </div>
            <ExtraInfo colorsCursor={cursor} stylesCursor={styleCursor} />
        </>
    )
}

export default ViewCarousel;