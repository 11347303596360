import { createSlice } from "@reduxjs/toolkit";

interface initialState {
    dicePreview: string,
}

const initialState: initialState = {
    dicePreview: ''
}


const previewerSlice = createSlice({
    name: 'previewer',
    initialState,
    reducers: {
        setDicePreview: (state, action) => { state.dicePreview = action.payload },
    },
    selectors: {
        getDicePreview: (state) => state.dicePreview
    }
})

export const {
    setDicePreview
} = previewerSlice.actions;

export const {
    getDicePreview
} = previewerSlice.selectors;

export default previewerSlice;