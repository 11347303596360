import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTablet } from "@fortawesome/pro-regular-svg-icons/faTablet"
import { faTruck } from "@fortawesome/pro-regular-svg-icons/faTruck"
import React, { useState } from 'react';
import { Variants, motion } from 'framer-motion';
import { styled } from '@stitches/react';

const TestWrapper = styled(motion.div, {
    '&[data-ishovered="true"]': {
        alignSelf: "flex-end",
        marginBottom: "16px"
    }
})

const ICONS = {
    'faTablet': faTablet,
    'faTruck': faTruck
}

interface ICard {
    url: string,
    imgUrl: string,
    icon: "faTablet" | "faTruck",
    caption: string
}

const MAIN_VARIANTS: Variants = {
    "animate": {},
    "hovered": {}
}

const FILTER_COLOR_VARIANTS: Variants = {
    "animate": {
        opacity: .9
    },
    "hovered": {
        opacity: 0
    }
}

const FILTER_FADE_VARIANTS: Variants = {
    "animate": {
        opacity: 0

    },
    "hovered": {
        opacity: 1,
        backgroundImage: "linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,0), rgba(0,0,0,.4))"
    }
}

const ICON_VARIANTS: Variants = {
    "animate": {
        opacity: 1
    },
    "hovered": {
        opacity: 0
    }
}

const BAR_VARIANTS: Variants = {
    "animate": {
        opacity: 0
    },
    "hovered": {
        opacity: 1
    }
}

const CategoryCard: React.FC<ICard> = ({ url, imgUrl, icon, caption }) => {
    const [variant, setVariant] = useState<string>("animate");

    return (
        <Link className='max-w-min' to={url}>
            <motion.div className="w-72 h-96 flex justify-center items-center relative"
                variants={MAIN_VARIANTS}
                animate={variant}
                onHoverStart={(e) => { setVariant('hovered') }}
                onHoverEnd={(e) => { setVariant('animate') }}
            >

                {/* Background image */}
                <img src={imgUrl} className='size-full object-cover absolute' alt="" />

                {/* Color Filter */}
                <motion.div
                    className="size-full bg-red-900 absolute"
                    variants={FILTER_COLOR_VARIANTS}
                    animate={variant}
                // whileHover="hovered"
                />

                {/* Fade Filter */}
                <motion.div
                    className='size-full absolute'
                    variants={FILTER_FADE_VARIANTS}
                    animate={variant}
                />

                <motion.div
                    className="absolute bottom-0 h-1 w-72 border-b-4 border-black opacity-0"
                    variants={BAR_VARIANTS}
                    animate={variant}
                />

                <TestWrapper
                    className='flex flex-col justify-center items-center gap-y-4 z-10 text-white'
                    data-ishovered={variant === 'hovered' ? true : false}
                    layout
                >
                    <motion.div
                        variants={ICON_VARIANTS}
                    >
                        <FontAwesomeIcon className='text-6xl' icon={ICONS[icon]} />
                    </motion.div>
                    <p className='text-teko italic font-bold text-5xl'>
                        {caption}
                    </p>
                </TestWrapper>



            </motion.div>

        </Link>
    )
}

export default CategoryCard;