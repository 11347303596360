import React from 'react';
import PageFrame from '../PageFrame';
import DiceSection from './Dice/DiceSection';
import BoogieSection from './Boards/BoogieSection';
import SectionHeader from './SectionHeader';
// import { useLocation, useOutlet } from 'react-router-dom';
// import { AnimatePresence } from 'framer-motion';
// import BoogieCard from './Boards/LCDLifePad';
// import DiceShopCard from './Dice/DiceShopCard/DiceShopCard';

const ProductPage: React.FC = () => {
    // const loc = useLocation()
    // const o = useOutlet();

    return (
        <PageFrame>
            <div className='flex flex-col items-center pb-12 shadow-lg gap-y-12 relative'>
                <SectionHeader header='Products'>
                    The Tryhard catalogue ranges from tablets to brushes to dice and sleeve protectors, with more on the way!
                </SectionHeader>
                {/* <AnimatePresence mode='wait'>
                    {o && React.cloneElement(o, { key: loc.pathname })}
                </AnimatePresence> */}
                <DiceSection />
                <BoogieSection />
            </div>
        </PageFrame>
    )
}

export default ProductPage;