import React, { forwardRef, useRef } from 'react';
import { motion, useDragControls, useMotionValue, useMotionValueEvent, useScroll, Variants } from 'framer-motion';


const SUBMENU_VARIANTS: Variants = {
    'initial': { opacity: 0, x: 20 },
    'animate': { opacity: 1, x: 0, transition: { staggerChildren: .5 } },
    'exit': { opacity: 0, x: 20 }
}

interface ISubmenuProps {
    children: React.ReactNode
}

const Submenu = forwardRef<any, ISubmenuProps>(({ children }, ref) => {

    const listRef = useRef<HTMLDivElement>(null!);
    const dragY = useMotionValue(0);
    const {scrollYProgress } = useScroll({ container: listRef })
    const scrollbarRef = useRef<HTMLDivElement>(null);
    const controls = useDragControls();

    const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
        let ceiling = (listRef.current!.offsetHeight - 48);
        let change = scrollYProgress.get() * ceiling;
        if (change < 0) change = 0;
        if (change > ceiling) change = ceiling;
        dragY.set(change);
    }

    const handleOnDrag = () => {
        let ceiling = (listRef.current!.offsetHeight - 48);
        let progress = dragY.get() / ceiling;
        if (progress < 0) progress = 0;
        if (progress > 1) progress = 1;
        scrollYProgress.set(progress)
        listRef.current!.scrollTop = progress * ceiling;
    }

    useMotionValueEvent(dragY, "change", () => {
        let ceiling = (listRef.current!.offsetHeight - 48);
        if (dragY.get() < 0) dragY.set(0);
        if (dragY.get() > ceiling) dragY.set(ceiling);
    })

    return (
        <motion.div
            layout
            ref={ref}
            key={'materialsSubmenu'}
            className='flex bg-white rounded-bl pr-4 pl-3 pb-2 relative'
            variants={SUBMENU_VARIANTS}
            initial='initial'
            animate='animate'
            exit='exit'
        >
            <div
                className='flex flex-col max-h-[338px] overflow-y-auto no-scrollbar pointer-events-auto'
                ref={listRef}
                onScroll={handleOnScroll}
            >
                {children}
                {/* Scrollbar */}
                <div
                    className='h-full flex absolute right-0 z-10 hover:cursor-pointer pointer-events-auto pl-2'
                    onPointerDown={(e) => { controls.start(e, { snapToCursor: true }) }}
                    ref={scrollbarRef}
                >
                    <motion.div
                        drag="y"
                        // dragListener={false}
                        dragControls={controls}
                        dragConstraints={scrollbarRef}
                        dragElastic={0}
                        dragMomentum={false}
                        onDrag={handleOnDrag}
                        style={{
                            y: dragY
                        }}
                        className='pl-2 w-1 h-12 bg-red-500 ml-1 hover:cursor-pointer relative z-50 pointer-events-auto'
                    />
                </div>
            </div>
        </motion.div>
    )
})

export default Submenu;