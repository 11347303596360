import React from "react";
import ToastOverlay from "./ToastsOverlay";
import PreviewOverlay from "./PreviewOverlay";

const GrandOverlay: React.FC = () => {

    return (
        <div  
            className="z-50 w-screen h-screen relative"
        >
            <ToastOverlay />
            <PreviewOverlay />
        </div>
    )
}

export default GrandOverlay;