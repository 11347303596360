

import { createSlice } from "@reduxjs/toolkit";
import { allDice as dice } from "../misc/infoDice2";

let colors: string[] = [];
let styles: string[] = [];
let materials: string[] = [];

// assemble colors
let colorsSet: Set<string> = new Set();
for (let k in dice) {
    colorsSet.add(dice[k].color)
}
// assemble styles
let stylesSet: Set<string> = new Set();
for (let k in dice) {
    stylesSet.add(dice[k].style)
}
// assemble materials
// let materialsSet = new Set();
// dice.forEach(p => materialsSet.add(p.material))

colors = Array.from(colorsSet);
styles = Array.from(stylesSet);

const stylesToColors: { [key:string]: string[] } = {};
styles.forEach(s => {
    let buffer: string[] = [];
    for (let k in dice) {
        if (dice[k].style === s) buffer.push(dice[k].color);
    }
    stylesToColors[s] = buffer;
})

interface metaState {
    colors: string[],
    styles: string[],
    materials: string[],
    stylesToColors: { [key:string]: string[] }
}

const initialState: metaState = {
    colors,
    styles,
    materials,
    stylesToColors
}

const metaSlice = createSlice({
    name: 'meta',
    initialState,
    reducers: {

    },
    selectors: {
        getColors: (state) => state.colors,
        getStyles: (state) => state.styles,
        getStylesToColors: (state) => state.stylesToColors
    }
})

export const {
    getColors, getStyles,
    getStylesToColors
} = metaSlice.selectors;

export default metaSlice;