import React from 'react';
import PageFrame from '../PageFrame';

const ContactPage: React.FC = () => {
    return (
        <PageFrame>
            <div className='flex flex-col items-center pb-12'>
                {/* Title */}
                <div className="pb-12">
                    <p className="font-semibold text-teko italic text-6xl text-red-950 max-w-fit">
                        Contact
                    </p>
                    <div className="border-b-4 border-red-950 h-1 w-full" />
                </div>

                {/* Body */}
                <div className='flex flex-col justify-center items-center gap-y-6 px-12'>
                    {/* Grow your business with our merchandise. Call today [XXX-XXX-XXXX] */}
                    <p className='text-center'>Grow your business with Tryhard merchandise. Call now to schedule a meeting &#40;we'll come to YOU!&#41;!</p>
                    <p className='text-3xl font-mono text-red-950'>XXX-XX-XXXX</p>
                </div>
            </div>
        </PageFrame>
    )
}

export default ContactPage;