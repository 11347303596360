import React, { useEffect, useMemo, useState } from 'react';
import useClickoutside from '../../../../../../hooks/useClickoutside';
import ShopTab from './ShopTab';
import { useSelector } from 'react-redux';
import { getColor, getSelection as getDiceSelection, getStyle, setColor, setSelection, setStyle } from '../../../../../../store/diceFilters';
// import CartMenu from './CartMenu';
import { useDispatch } from 'react-redux';
import { getDiceProducts } from '../../../../../../store/products';
import Submenus from './Submenus';
import ViewCarousel from './ViewCarousel';
// import { motion } from 'framer-motion';
import { DiceProduct } from '../../../../../../../types/types';
import { hasKeys } from '../../../../../../utils/helper';

const DiceShopCard: React.FC = () => {
    const dispatch = useDispatch();
    // State
    const serial = useSelector(getDiceSelection); // serial
    const style = useSelector(getStyle);
    const color = useSelector(getColor);
    // const selectedSize = useSelector(getSize);
    const products = useSelector(getDiceProducts);
    // Menu
    const [stylesOpen, setMaterialsOpen] = useState<boolean>(false);
    const [colorsOpen, setColorsOpen] = useState<boolean>(false);
    // Menu controls
    const collapseMenus = () => {
        setMaterialsOpen(false);
        setColorsOpen(false);
    }
    const openMaterials = () => {
        setMaterialsOpen(true);
        setColorsOpen(false);
    }
    const openColors = () => {
        setColorsOpen(true);
        setMaterialsOpen(false);
    }

    const topRef = useClickoutside(() => collapseMenus());

    const filtered: DiceProduct[] = useMemo(() => {
        let f: DiceProduct[] = [];
        for (let k in products) {
            if (products[k].style === style) f.push(products[k])
        }
        return f;
    }, [style])

    useEffect(() => {
        if (serial) {
            dispatch(setColor(products[serial].color));
            dispatch(setStyle(products[serial].style));
        }
    }, [serial])

    useEffect(() => {
        if (filtered[0]) {
            dispatch(setSelection(filtered[0].serial));
            dispatch(setColor(filtered[0].color));
            dispatch(setStyle(filtered[0].style));
        }
    }, [filtered])

    useEffect(() => {
        if (hasKeys(products) && !serial) {
            for (let k in products) {
                dispatch(setSelection(products[k].serial));
                return;
            }
        }
     }, [])

    return (
        <div
            ref={topRef}
            className='flex flex-col h-96 w-72 md:min-h-96 md:min-w-72 relative overflow-hidden'
        >
            <Submenus onSelect={collapseMenus} stylesOpen={stylesOpen} colorsOpen={colorsOpen} />
            {/* Tabs */}
            <div className='flex flex-col relative'>
                <ShopTab category='Materials' active={stylesOpen} onSelection={openMaterials} selection={style} tabBgcolor='bg-red-500' />
                <ShopTab category='Colors' active={colorsOpen} onSelection={openColors} selection={color} tabBgcolor='bg-red-700' />
            </div>
            {/* Carousel */}
            <ViewCarousel filtered={filtered} />
        </div>
    )
}

export default DiceShopCard;