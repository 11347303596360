import { motion, Variants } from 'framer-motion';
import React, { ReactNode, useState } from 'react';

interface ISubmenuOption {
    children?: ReactNode,
    onClick: Function
}

const SUBMENU_OPTIONS_VARIANTS: Variants = {
    'initial': { opacity: 0, x: 50 },
    'animate': { opacity: 1, x: 0 },
    'whileHover': {},
    'exit': { opacity: 0, x: 50 }
}

const SUBMENU_OPTIONS_BAR_VARIANTS: Variants = {
    'initial': { width: 0, },
    'animate': { width: '35%' },
    'whileHover': { width: '55%' }
}

const SubmenuOption: React.FC<ISubmenuOption> = ({ children, onClick }) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
        <motion.div
            className='flex flex-col items-end w-full text-right pointer-events-auto hover:cursor-pointer capitalize'
            onClick={() => onClick()}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            variants={SUBMENU_OPTIONS_VARIANTS}
            initial="initial"
            animate="animate"
            whileHover='whileHover'
            exit="exit"
            layout
        >
            {children}
            <motion.div
                className='border-b-2 border-slate-700'
                variants={SUBMENU_OPTIONS_BAR_VARIANTS}
                initial='initial'
                animate={hovered ? 'whileHover' : 'animate'}
                layout
            />
        </motion.div>
    )
}

export default SubmenuOption;