import React from 'react';
import DiceShopCard from './DiceShopCard/DiceShopCard';
import SectionHeader from '../SectionHeader';

const DiceSection: React.FC = () => {
    return (
        <div className='flex flex-col items-center gap-y-3'>
            <SectionHeader header='Dice'>
                Featuring <span className='font-semibold'>over 600</span> sets varying in style, material, colors, and faces.
            </SectionHeader>
            <DiceShopCard />
        </div>
    )
}

export default DiceSection;