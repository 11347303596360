import { Variants, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IFooterItemProps {
    route: string,
    comingSoon: boolean | undefined,
    content: string
}

const EFFECT_VARIANTS: Variants = {
    initial: { width: "0px" },
    animate: (hovered: boolean) => ({ width: hovered ? "100%" : "0px" })
}

const FooterItem: React.FC<IFooterItemProps> = ({ route, content, comingSoon }) => {
    const location = useLocation();
    const [hovered, setHovered] = useState<boolean>(false);

    return (
        <Link to={comingSoon ? location.pathname : route}>
            <div
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className='relative'
            >
                <motion.p whileHover={{ scale: 1.05 }}>{content}</motion.p>
                <motion.div
                    className='bottom-0 right-0 h-1 bg-white absolute'
                    variants={EFFECT_VARIANTS}
                    initial="initial"
                    animate="animate"
                    custom={hovered}
                >

                </motion.div>
            </div>
        </Link>
    )
}

export default React.memo(FooterItem);