import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/sharp-regular-svg-icons/faArrowLeft';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackArrow: React.FC = () => {
    const nav = useNavigate();

    return (
        <button
            type="button"
            className='size-12 bg-red-600 flex justify-center items-center hover:cursor-pointer'
            onClick={() => {
                nav(-1);
            }}
        >
            <FontAwesomeIcon className='text-white' icon={faArrowLeft} />
        </button>
    )
}

export default BackArrow;