import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiceProduct, THGProduct } from "../../types/types";
import { allDice as dice } from "../misc/infoDice2";

interface productsState {
    items: { [key: string]: THGProduct },
    dice: { [key: string]: DiceProduct }
}

const initialState: productsState = {
    items: {},
    dice
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        addProduct: (state, action: PayloadAction<THGProduct>) => {
            state.items[action.payload.serial] = action.payload
        },
        addDiceProduct: ((state, action: PayloadAction<DiceProduct>) => {
            state.dice[action.payload.serial] = action.payload
        })
    },
    selectors: {
        getProducts: (state) => state.items,
        getDiceProducts: (state) => state.dice
    }
})

export const {
    addProduct, addDiceProduct
} = productsSlice.actions;

export const {
    getProducts, getDiceProducts
} = productsSlice.selectors;

export default productsSlice;