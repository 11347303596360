import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getHistory } from '../../store/cart';
import { AnimatePresence, motion, useTime, useTransform, Variants } from 'framer-motion';
import useDebounce from '../../hooks/useDebounce';
import { THGProduct } from '../../../types/types';

const TOAST_VARIANTS: Variants = {
    initial: {
        opacity: .7,
        y: -20,
        transition: {
            duration: .2,
            ease: "easeOut",
        }
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        x: 50,
        opacity: 0,
        transition: {
            duration: 1,
        }
    }

}

const CartToast = forwardRef<any, { item: THGProduct, onExpire: Function }>(({ item, onExpire }, ref) => {
    useDebounce(() => { onExpire() }, 3000, [history])

    const t = useTime();
    const barWidth = useTransform(t, [0, 3000], ["0%", "100%"]);
    const circlePathLength = useTransform(t, [0, 3000], [0,1])
    const circleColor = useTransform(t, [0, 3000], ['rgb(239 68 68 / 0)', 'rgb(69 10 10)' ])

    return (
        <motion.div
            ref={ref}
            variants={TOAST_VARIANTS}
            initial='initial'
            animate='animate'
            exit='exit'
            layout
            className='border-b-2 border-b-red-950 rounded-lg bg-white shadow-xl px-3 py-2 text-wrap text-center flex items-center justify-center gap-x-2 relative'
        >
            <p>{item.quantity} item(s) added to cart.</p>
            <svg width='32' height='32' viewBox='0 0 32 32' className='toast-progress-svg -rotate-90'>
                <circle cx='16' cy='16' r='16' pathLength='1' className='toast-progress-bg' />
                <motion.circle
                    className='toast-progress-indicator'
                    cx='16'
                    cy='16'
                    r='12'
                    pathLength='1'
                    style={{ pathLength: circlePathLength, stroke: circleColor }}
                />

            </svg>

            <div className='size-full absolute inset-0'>
                <div className='rounded-lg w-full h-2 bg-gradient-to-r from-red-500 to-red-800 overflow-hidden absolute bottom-0'>
                    <div className='rounded-lg size-full bg-white mb-[4px] absolute bottom-0' />
                </div>
                {/* Eraser */}
                <motion.div style={{ width: barWidth }} layout className='h-2 border-b-2 border-b-red-950 rounded-lg absolute bottom-0' />
            </div>

        </motion.div>
    )
})

const ToastOverlay: React.FC = () => {

    const history = useSelector(getHistory);
    const tExpired: { [key: number]: boolean } = {};
    history.forEach((v, i) => tExpired[i] = false)
    const [expired, setExpired] = useState<{ [key: number]: boolean }>({})
    const onExpireHandler = (i: number) => {
        let newExpired = { ...expired };
        newExpired[i] = true;
        setExpired(newExpired);
    }

    return (
        <div className='pointer-events-none flex justify-center size-full absolute'>
            <div className='fixed top-0 flex flex-col items-center gap-y-4 pt-4'>
                <AnimatePresence mode='sync'>
                    {history.map((t, i) => (!expired[i]) && <CartToast onExpire={() => onExpireHandler(i)} item={t} key={i} />)}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default ToastOverlay;