import React from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/sharp-regular-svg-icons/faChevronLeft';

interface ITabProps {
    category: string,
    active: boolean,
    onSelection: Function,
    selection: string | null,
    tabBgcolor: string
}

const TAB_VARIANTS: Variants = {
    'animate': { paddingLeft: 24 },
    'whileHover': { paddingLeft: 36 },
}

const MATERIALS_CONTENTS_VARIANTS: Variants = {
    'animate': { x: 0 },
    'whileTap': { x: 10 }
}

const SELECTION_VARIANTS: Variants = {
    'initial': { opacity: 0, y: 16 },
    'animate': { opacity: 1, y: 0 },
    'exit': { opacity: 0, y: -16 }
}

const ShopTab: React.FC<ITabProps> = ({ category, active, onSelection, selection, tabBgcolor }) => {
    return (
        <div
            className='flex flex-col min-h-min max-h-min'
            onClick={() => onSelection(true)}
        >
            <div className='flex'>
                <motion.div
                    className={'min-w-min max-w-min flex items-center text-white pt-1 text-3xl text-teko italic pr-8 hover:cursor-pointer relative overflow-hidden' + ` ${tabBgcolor}`}
                    variants={TAB_VARIANTS}
                    animate={active ? 'whileHover' : 'animate'}
                    whileHover='whileHover'
                    whileTap='whileTap'
                    onClick={() => {
                        onSelection(true)
                    }}
                    layout
                >
                    <motion.p
                        className='select-none'
                        variants={MATERIALS_CONTENTS_VARIANTS}
                        whileTap='whileTap'
                    >
                        {category}
                    </motion.p>
                    <div className='bg-white rotate-45 h-[200%] -mr-[50%] mt-[25%] w-[50%] absolute right-0' />
                </motion.div>
                <div className='grow flex justify-end'>
                    {selection ? (
                        <div className='flex flex-col items-end mr-2 text-center'>
                            <AnimatePresence mode='popLayout'>
                                <motion.div
                                    className='capitalize text-truncate'
                                    variants={SELECTION_VARIANTS}
                                    initial='initial'
                                    animate='animate'
                                    exit='exit'
                                    key={selection}
                                >
                                    {selection}
                                </motion.div>
                                <motion.div
                                    className='h-px border-b-2 border-gray-800 block'
                                    layout
                                    initial={{ width: 0 }}
                                    animate={{ width: 32, transition: { duration: .75, ease: "easeIn" } }}
                                />
                            </AnimatePresence>

                        </div>
                    ) : (
                        <div className='flex justify-end'>
                            <div className='flex mr-2'>
                                <motion.div
                                    initial={{ x: 0 }}
                                    animate={{ x: -15, transition: { duraton: .5, repeatType: "loop", repeat: Infinity } }}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </motion.div> Tap for menu
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ShopTab;