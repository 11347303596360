import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import GrandOverlay from './components/Overlay/GrandOverlay';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import './styles/styles';

const App: React.FC = () => {

    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className='w-dvh h-dvh flex flex-col text-stone-800 text-lg relative'>
                    <GrandOverlay />
                    <Header />
                    <Main />
                    <Footer />
                </div>
            </BrowserRouter>
        </Provider>
    )
}

export default App;