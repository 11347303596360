// @ts-nocheck
import { Canvas } from '@react-three/fiber';
import { animate, useMotionValue, useTransform } from 'framer-motion';
import React, { MouseEventHandler, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { motion as motion3d } from 'framer-motion-3d';
// import { Boogie3D } from '../../../../3d/Boogie3D';
import { Boogie3D2 } from '../../../../../3d/Boogie3D2';

const BoogieCard: React.FC = () => {

    const canvasRef = useRef<HTMLDivElement>(null!);

    const [canvasW, setCanvasW] = useState<number>(288);
    const [canvasH, setCanvasH] = useState<number>(384);

    useEffect(() => {
        if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();

            setCanvasW(rect.width);
            setCanvasH(rect.height)
        }
    }, [])

    const y = useMotionValue(canvasH / 2);
    const x = useMotionValue(canvasW / 2);

    const rotateY = useTransform(x, [0, canvasW], [-.4, .4])
    const rotateX = useTransform(y, [0, canvasH], [-.3, .3])

    const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();

        const newX = e.clientX - rect.left;
        const newY = e.clientY - rect.top;

        // x.set(newX);
        // y.set(newY);

        animate(x, newX, { duration: .3, ease: "easeOut" });
        animate(y, newY, { duration: .3, ease: "easeOut" });
    }

    // const handleMouseEnter = useCallback<MouseEventHandler>((e) => {
    //     const rect = e.currentTarget.getBoundingClientRect();

    //     animate(x, e.clientX - rect.left, { duration: .01, ease: "easeOut" });
    //     animate(y, e.clientY - rect.top, { duration: .01, ease: "easeOut" });
    // }, [canvasW, canvasH])

    const handleMouseLeave = useCallback<MouseEventHandler>((e) => {
        animate(x, canvasW / 2, { duration: .3, ease: "easeOut" });
        animate(y, canvasH / 2, { duration: .3, ease: "easeOut" });

    }, [canvasW, canvasH])

    const handleOnClick = useCallback<MouseEventHandler>((e) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();

        const newX = e.clientX - rect.left;
        const newY = e.clientY - rect.top;

        animate(x, newX, { duration: .3, ease: "easeOut" });
        animate(y, newY, { duration: .3, ease: "easeOut" });
    }, [canvasW, canvasH])

    return (
        <div className='flex flex-col h-96 w-72 md:min-h-96 md:min-w-72'
            ref={canvasRef}
            onMouseMove={handleMouseMove}
            // onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleOnClick}
        >
            <Suspense fallback={null}>
                <Canvas>
                    <motion3d.ambientLight intensity={Math.PI / 2} />
                    <motion3d.spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
                    <motion3d.pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />

                    <Boogie3D2
                        scale={3.5}
                        rotateX={rotateX}
                        rotateY={rotateY}
                    />
                </Canvas>
            </Suspense>
            <div className='flex justify-center items-center w-full bg-red-500'>
                <motion.p
                    className='text-4xl text-teko font-semibold text-white italic pt-2 hover:cursor-pointer'
                    whileHover={{ scale: 1.1 }}
                >BOOGIE BOARD</motion.p>

            </div>
        </div>
    )
}

export default BoogieCard;