import { Variants } from "framer-motion"

export const SMOOTH_IN_OUT_VARIANTS: Variants = {
    initial: {
        opacity: 0,
        x: -30
    },
    animate: {
        opacity: 1,
        x: 0
    },
    exit: {
        opacity: 0,
        x: 30
    }
}