import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/sharp-regular-svg-icons/faBars';
import { faXmark } from '@fortawesome/sharp-regular-svg-icons/faXmark';
import { AnimatePresence, motion } from "framer-motion";
import useTimeout from '../../hooks/useTimeout';
import HeaderHamburgerItem from "./HeaderHamburgerItem";
import useClickoutside from "../../hooks/useClickoutside";
import { PAGE_HEAD } from "../../misc/ids";
import scrollIntoView from "scroll-into-view-if-needed";

const MENU_VARIANTS = {
    open: {
        height: "auto",
        width: "auto"
    },
    close: {
        height: "36px",
        width: "36px"
    }
}

const BACKGROUND_VARIANTS = {
    open: {
        scale: "10",
    },
    close: {
        scale: "1"
    }
}

const BUTTON_VARIANTS = {
    fadeIn: { opacity: 1 },
    fadeOut: { opacity: .5 }
}

const LIST_VARIANTS = {
    initial: {
        x: 50,
        transition: {
            when: "afterChildren",
        }
    },
    animate: {
        x: 0,
        transition: {
            // when: "beforeChildren",
            staggerChildren: 0.3,

        }
    }
}

const HeaderHamburger: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        setOpen(!open);
    }

    const [mouseLeave, setMouseLeave] = useState<boolean>(false);
    const { reset, clear } = useTimeout(() => { setOpen(false) }, 500)

    const handleMouseLeave: MouseEventHandler<HTMLDivElement> = useCallback((e) => setMouseLeave(true), []);
    const handleMouseEnter: MouseEventHandler<HTMLDivElement> = useCallback((e) => setMouseLeave(false), []);

    useEffect(() => {
        if (open) {
            { mouseLeave? reset() : clear() }
        }
        // open && mouseLeave && reset();
    }, [reset, mouseLeave])
    useEffect(() => clear, []);

    const ref = useClickoutside(() => setOpen(false));

    const handleClickSubhandler = () => { 
        setOpen(false);        
        const node = document.querySelector(`#${PAGE_HEAD}`)
        node && scrollIntoView(node);
    }

    return (
        <div
            className="z-20 size-9 relative"
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
            style={{ filter: "drop-shadow(0 25px 25px rgb(0 0 0 / 0.15))" }}
            ref={ref}
        >
            <motion.div
                className="min-w-9 min-h-9 drop-shadow-2xl rounded-sm overflow-clip top-0 right-0 absolute"
                variants={MENU_VARIANTS}
                animate={open ? "open" : "close"}
                transition={{ duration: .1 }}
                layout
            >

                {/* Button */}

                <button
                    className="z-10 size-9 top-0 right-0 absolute"
                    typeof="button"
                    onClick={handleClick}
                >
                    <AnimatePresence initial={false} mode="popLayout">
                        {open ? (
                            <motion.div
                                variants={BUTTON_VARIANTS}
                                key={1}
                                initial="fadeOut"
                                animate="fadeIn"
                                exit="fadeOut"
                                transition={{ duration: .05 }}
                            >
                                <FontAwesomeIcon className="z-20 text-red-600 text-2xl relative" icon={faXmark} />
                            </motion.div>
                        ) : (
                            <motion.div
                                variants={BUTTON_VARIANTS}
                                key={"234234"}
                                initial="fadeOut"
                                animate="fadeIn"
                                exit="fadeOut"
                                transition={{ duration: .05 }}
                            >
                                <FontAwesomeIcon className="z-20 text-red-600 text-xl relative" icon={faBars} />
                            </motion.div>
                        )}

                    </AnimatePresence>
                </button>

                {/* Background */}
                <motion.div
                    className="size-9 rounded-sm bg-white absolute"
                    variants={BACKGROUND_VARIANTS}
                    initial={open ? "open" : "close"}
                    animate={open ? "open" : "close"}
                    transition={{ duration: .1 }}
                />
                {/* <motion.circle
                    className="size-7 bg-white absolute"
                    cx={500}
                    animate={open ? { cx: 256 } : { cx: 48 }}
                    transition={{ duration: .1}}
                /> */}

                {/* Body */}
                {open &&
                    <motion.ul
                        className="z-10 px-2 min-w-32 pb-2 mt-9 flex flex-col text-2xl text-red-600 text-teko gap-y-1 relative"
                        variants={LIST_VARIANTS}
                        initial="initial"
                        animate="animate"
                    >
                        <HeaderHamburgerItem content={"For Partners"} route="/contact" onTouchSubhandler={handleClickSubhandler} subVariants={LIST_VARIANTS} />
                        <HeaderHamburgerItem content={"Products"} route="/products" onTouchSubhandler={handleClickSubhandler} subVariants={LIST_VARIANTS} />
                        <HeaderHamburgerItem content={"About"} route="/about" onTouchSubhandler={handleClickSubhandler} subVariants={LIST_VARIANTS} />
                    </motion.ul>
                }
            </motion.div>

        </div>

    )
}

export default HeaderHamburger;