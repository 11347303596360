import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { THGProduct } from "../../types/types";


interface cartState {
    items: { [key: string]: THGProduct },
    history: THGProduct[]
}

const initialState: cartState = {
    items: {},
    history: []
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<THGProduct>) => {
            if (state.items[action.payload.serial]) {
                state.items[action.payload.serial].quantity += action.payload.quantity
            } else {
                state.items[action.payload.serial] = action.payload
            }
        },
        decrementItem: (state, action: PayloadAction<THGProduct>) => {
            if (!state.items[action.payload.serial]) return;
            state.items[action.payload.serial].quantity -= action.payload.quantity;
            if (state.items[action.payload.serial].quantity <= 0) {
                state.items[action.payload.serial].quantity = 0;
            }
        },
        setItem: (state, action: PayloadAction<THGProduct>) => {
            state.items[action.payload.serial] = action.payload
        },
        removeItem:(state, action: PayloadAction<THGProduct>) => {
            delete state.items[action.payload.serial]
        },
        removeItems: (state, action) => {
            state.items = {}
        },
        appendHistory: (state, action) => {
            state.history.push(action.payload)
        },
    },
    selectors: {
        getItems: (state) => state.items,
        getHistory: (state) => state.history
    }
})

export const {
    addItem, decrementItem,
    setItem,
    removeItem, removeItems,
    appendHistory
} = cartSlice.actions;

export const {
    getItems, getHistory
} = cartSlice.selectors;

export default cartSlice;