import React from 'react';
import PageFrame from '../PageFrame';

const AboutPage: React.FC = () => {
    return (
        <PageFrame>
            <div className='flex flex-col items-center'>
                <div className="pb-12">
                    <p className="font-semibold text-teko italic text-6xl text-red-950 max-w-fit">
                        About
                    </p>
                    <div className="border-b-4 border-red-950 h-1 w-full" />
                </div>
                <div className='flex justify-center items-center'>
                    <p>(forthcoming)</p>
                </div>
            </div>
        </PageFrame>
    )
}

export default AboutPage;